import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import generalReducer from "./slices/general";
import usersReducer from "./slices/users";
import officeReducer from "./slices/office";
import matterReducer from "./slices/matter";
import attachmentReducer from "./slices/attachment";
import courtsReducer from "./slices/courts";
import financeReducer from "./slices/finance";
import reportsReducer from "./slices/reports";
import deptCollectionReducer from "./slices/deptCollection";
import StatisticReducer from "./slices/statistics";
import sessionsReducer from "./slices/sessions";
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const generalPersistConfig = {
  key: "general",
  storage,
  keyPrefix: "redux-",
  whitelist: [
    "isCheked",
    "activeStepperNumber",
    "tab",
    "tapEditPage",
    "contractSelected",
    "radioState",
    "slides",
    "isChekedSlideButton",
    "userId",
    "empName",
    "empId",
    "clientInfo",
  ],
};

const matterPersistConfig = {
  key: "matter",
  storage,
  keyPrefix: "redux-",
  whitelist: ["matterId", "expensesList", "sessionId"],
};

const financePersistConfig = {
  key: "finance",
  storage,
  keyPrefix: "redux-",
  whitelist: ["expenses", "count", "total_cost", "dataToExport"],
};
const reportsConfig = {
  key: "reports",
  storage,
  keyPrefix: "redux-",
  whitelist: [""],
};
const deptCollectionConfig = {
  key: "deptCollection",
  storage,
  keyPrefix: "redux-",
  whitelist: [
    "clientId",
    "opponentId",
    "clientName",
    "tempId",
    "clientContract",
    "template",
    "fields",
    "reqFields",
    "opponentsInFile",
    "expenseStatements",
    "ExpenseId",
    "tempData"
    
  ],
};
const StatisticConfig = {
  key: "Statistic",
  storage,
  keyPrefix: "redux-",
  whitelist: [""],
};

const sessionsConfig = {
  key: "sessions",
  storage,
  keyPrefix: "redux-",
  whitelist: [""],
};

const rootReducer = combineReducers({
  general: persistReducer(generalPersistConfig, generalReducer),
  users: usersReducer,
  office: officeReducer,
  matter: persistReducer(matterPersistConfig, matterReducer),
  attachment: attachmentReducer,
  courts: courtsReducer,
  finance: persistReducer(financePersistConfig, financeReducer),
  reports: persistReducer(reportsConfig, reportsReducer),
  deptCollection: persistReducer(deptCollectionConfig, deptCollectionReducer),
  Statistic: persistReducer(StatisticConfig, StatisticReducer),
  sessions: persistReducer(sessionsConfig, sessionsReducer),
});

export { rootPersistConfig, rootReducer };
