import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AddNewClientButton = () => {
  const navigate = useNavigate();

  const boxStyleOfSelect = {
    display: "flex",
    position: "relative",
    // bottom: 0,
    height: "43px",
    alignItems: "center",
    paddingRight: "12px",
    paddingLeft: "12px",
  };

  const addButtonStyle = {
    marginLeft: "8px",
    color: "#007bff",
  };

  return (
    <Box sx={boxStyleOfSelect}>
      <img
        onClick={() => navigate("/dashboard/lawsuit/add-new-client")}
        alt=""
        style={{ cursor: "pointer" }}
        src="/assets/icons/user-add.svg"
      />
      <Typography sx={addButtonStyle}>اضافة موكل جديد</Typography>
    </Box>
  );
};

export default AddNewClientButton;
