import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";

import { IconButton, Tooltip } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import CustudtExpenseListTableRow from "./TypesExpenseListTableRow";
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from "../../../../components/table";
import Iconify from "../../../../components/iconify";
import Scrollbar from "../../../../components/scrollbar/Scrollbar";
import LoadingTableRows from "../../../../components/loadingSkeleton/LoadingTableRows";
import TypesExpenseListTableRow from "./TypesExpenseListTableRow";
import { getExpensesService } from "../../../../redux/services/debt_collection_service";

// ----------------------------------------------------------------------

// --------- Style ------------

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  height: "auto",
  overflow: "unset",
  borderColor: theme.palette.primary.main,
  border: "1.99262px 1.99262px 9.9631px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  background: "#fff",
}));

const tableHolderSx = {
  minWidth: 800,
};

const TABLE_HEAD = [
  { id: "المصاريف", label: "المصاريف", align: "center" },
  { id: "القيمة الافتراضية", label: "القيمة الافتراضية", align: "center" },
  { id: "الوصف", label: "الوصف", align: "center" },
];

// ----------------------------------------------------------------------

export default function TypesExpenseList() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const { isLoading, expenses, expensesCount } = useSelector(
    (state) => state.deptCollection
  );
  // const [page, setPage] = useState(1);
  // const handleChange = (event, value) => {
  //   dispatch(getExpensesOppService({ id: ExpenseId, page: value }));

  //   setPage(value);
  // };

  const {
    dense,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    onSelectRow,
    onSelectAllRows,
    setSelected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();
  //

  const [tableData, setTableData] = useState([]);
  const handleChange = (event, value) => {
    setPage(value);

    dispatch(getExpensesService({ page: value }));
  };
  useEffect(() => {
    setTableData(expenses);
  }, [dispatch, expenses]);

  return (
    <>
      <StyledTableContainer>
        <TableSelectedAction
          dense={dense}
          numSelected={selected?.length}
          rowCount={tableData?.length}
          onSelectAllRows={(checked) =>
            onSelectAllRows(
              checked,
              tableData?.map((row) => row?.id)
            )
          }
          action={
            <Tooltip title="حذف">
              <IconButton color="#FFFFF">
                <Iconify icon="eva:trash-2-outline" />
              </IconButton>
            </Tooltip>
          }
        />{" "}
        <Scrollbar>
          <Table size={dense ? "small" : "medium"} sx={{ ...tableHolderSx }}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              numSelected={selected.length}
              onSort={onSort}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row) => row?.id)
                )
              }
            />

            <TableBody>
              <>
                {!isLoading ? (
                  <>
                    {tableData?.map((row) => (
                      <TypesExpenseListTableRow
                        key={row.id}
                        row={row}
                        onSelectRow={() => onSelectRow(row?.id)}
                        selected={selected?.includes(row?.id)}
                      />
                    ))}
                  </>
                ) : (
                  <LoadingTableRows number={3} cellsNum={3} />
                )}
              </>
              {tableData?.length === 0 && <TableNoData isNotFound />}
            </TableBody>
          </Table>
        </Scrollbar>
        <TablePaginationCustom
          count={expensesCount}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChange={handleChange}
          onRowsPerPageChange={onChangeRowsPerPage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </StyledTableContainer>
    </>
  );
}
