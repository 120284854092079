import React from "react";

import { Box, Grid } from "@mui/material";

import MainInformationForm from "../../accordion/MainInformationForm";

import FinanceAccordion from "../../accordion/FinanceAccordion";

import ContactInformation from "../../accordion/ContactInformation";

import OppDetailsSkeletion from "../../../../../components/skeleton/OppDetailsSkeletion";

import FileFinances from "../../accordion/FileFinances";

import ProceduresAccordion from "../../accordion/ProceduresAccordion";

// -----------------------------------------------------------------------------

//* ------- Styles --------

//_______________________________________________-

export default function DetailsTab({
  setValue,
  control,
  reset,
  isLoadingOppDetails,
  watch,
  methods,
  handleSubmit,
  onSubmit,
  defaultValues,
}) {
  // ------------- JSX Code ----------
  return (
    <>
      {isLoadingOppDetails ? (
        <OppDetailsSkeletion />
      ) : (
        <Box sx={{ pt: "40px" }}>
          <Grid container columnSpacing={10}>
            {/** المعلومات الاساسية */}
            <Grid item xs={12} sm={12} md={8}>
              <MainInformationForm
                setValue={setValue}
                control={control}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                reset={reset}
                defaultValues={defaultValues}
              />
            </Grid>

            {/** الماليات && ملعومات التواصل */}
            <Grid item xs={12} sm={12} md={4}>
              <Box mb={2}>
                <FinanceAccordion />
              </Box>

              <ContactInformation
                setValue={setValue}
                control={control}
                watch={watch}
                methods={methods}
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={10} mt="30px">
            <Grid item xs={12}>
              <FileFinances />
            </Grid>
          </Grid>
          <Grid container columnSpacing={10} mt="30px">
            <Grid item xs={12}>
              <ProceduresAccordion />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
