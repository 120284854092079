import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET ALL COURTS SESSION SERVICE
export const getAllCourtSessionService = createAsyncThunk(
  "reports/getAllCourtSessionService",
  async ({ page, session_id }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.getAllCourtSessionsURL}?page=${page}&session_id=${session_id}`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// POST =>  FILTER COURTS SERVICE
export const filterCourtsService = createAsyncThunk(
  "courts/filterCourtsService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  FILTER Experts SERVICE
export const filterExpertsService = createAsyncThunk(
  "courts/filterExpertsService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// POST =>  FILTER Administrative SERVICE
export const filterAdministrativeService = createAsyncThunk(
  "courts/filterAdministrativeService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// POST =>  FILTER Excute SERVICE
export const filterExcuteService = createAsyncThunk(
  "courts/filterExcuteService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// POST =>  DELETE Excute SERVICE
export const deleteSessionService = createAsyncThunk(
  "courts/deleteSessionService",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${URLS.deleteSessionService}${id}/delete/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// POST =>  DELETE Excute SERVICE
export const updateSessionService = createAsyncThunk(
  "courts/updateSessionService",
  async ({ id,data }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.update_session}/${id}`,data,
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
