import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";

import {
  useTable,
  TableHeadCustom,
  TableSelectedAction,
  TableNoData,
  TablePaginationCustom,
} from "../../../../../components/table";

import { IconButton, Tooltip } from "@mui/material";

import Iconify from "../../../../../components/iconify";

import Scrollbar from "../../../../../components/scrollbar";

import NewExpenseStatementTableRow from "./ExpensesListTableRow";

import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import LoadingTableRows from "../../../../../components/loadingSkeleton/LoadingTableRows";
import {
  getExpenseOpponents,
  getExpensesOppService,
} from "../../../../../redux/services/debt_collection_service";
import CustudtExpenseListTableRow from "./ExpensesListTableRow";

// ----------------------------------------------------------------------

// --------- Style ------------

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  height: "auto",
  overflow: "unset",
  borderColor: theme.palette.primary.main,
  border: "1.99262px 1.99262px 9.9631px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  background: "#fff",
}));

const tableHolderSx = {
  minWidth: 800,
};

const TABLE_HEAD = [
  { id: "المصروف", label: "المصروف", align: "center" },
  { id: "القيمة", label: "القيمة", align: "center" },
  { id: "التاريخ ", label: "التاريخ ", align: "center" },
  { id: "رقم الكشف", label: "رقم الكشف", align: "center" },
  { id: "ملاحظات", label: "ملاحظات", align: "center" },
];

// ----------------------------------------------------------------------

export default function ExpensesList() {
  const dispatch = useDispatch();

  const { isLoadingOppExp, OppExps, OppExpsCount, opponentId } = useSelector(
    (state) => state.deptCollection
  );
  console.log("OppExps", OppExps);
  const [page, setPage] = useState(1);
  const {
    dense,
    order,
    orderBy,

    selected,
    onSelectRow,
    onSelectAllRows,
    setSelected,
    //
    onSort,
  } = useTable();
  //

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(OppExps);
  }, [dispatch, OppExps]);
  const handleChange = (event, value) => {
    setPage(value);
    dispatch(getExpenseOpponents({ id: opponentId, page: value }));
  };
  return (
    <>
      <StyledTableContainer>
        <TableSelectedAction
          dense={dense}
          numSelected={selected?.length}
          rowCount={tableData?.length}
          onSelectAllRows={(checked) =>
            onSelectAllRows(
              checked,
              tableData?.map((row) => row?.id)
            )
          }
          action={
            <Tooltip title="حذف">
              <IconButton color="#FFFFF">
                <Iconify icon="eva:trash-2-outline" />
              </IconButton>
            </Tooltip>
          }
        />{" "}
        <Scrollbar>
          <Table size={dense ? "small" : "medium"} sx={{ ...tableHolderSx }}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              numSelected={selected.length}
              onSort={onSort}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row) => row?.id)
                )
              }
            />

            <TableBody>
              <>
                {!isLoadingOppExp ? (
                  <>
                    {tableData?.map((row) => (
                      <CustudtExpenseListTableRow
                        key={row.id}
                        row={row}
                        onSelectRow={() => onSelectRow(row?.id)}
                        //

                        selected={selected?.includes(row?.id)}
                      />
                    ))}
                  </>
                ) : (
                  <LoadingTableRows number={3} cellsNum={3} />
                )}
              </>
              {tableData?.length === 0 && <TableNoData isNotFound />}
            </TableBody>
          </Table>
        </Scrollbar>
        <TablePaginationCustom
          count={OppExpsCount}
          page={page}
          // rowsPerPage={rowsPerPage}
          handleChange={handleChange}
          // onRowsPerPageChange={onChangeRowsPerPage}
          dense={dense}
          // onChangeDense={onChangeDense}
        />
      </StyledTableContainer>
    </>
  );
}
