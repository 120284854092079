import { Navigate, useRoutes } from "react-router-dom";

//element
import {
  LoginPage,
  WelcomePage,
  ResetPassword,
  OfficeDetails,
  AddNewUserPage,
  AddNewUser,
  OverViewPage,

  //
  CreateProcedures,
  //
  EmployeePage,
  AddNewEmployee,
  //
  EditEmployee,
  //
  PersonalProfilePage,
  CollectionFinancesPage,
  ExpenseStatementPage,
  NewExpenseStatementPage,
  ClientsCollectionPage,
  AddNewDeptClientPage,
  CollectionPage,
  CreateInvoice,
  UploadFilePage,
  OpponentPage,
  EditProcedures,
  TasksProceduresPage,
  CreateTemplatePage,
  CreateTemplateTable,
  SearchOppontentPage
} from "./elements";

import DashboardLayout from "../layouts/dashboard/DashboardLayout";

import UsersPage from "../pages/dashboard/users/UsersPage";
import OpponentProfilePage from "../pages/dashboard/collection/OpponentProfilePage";
import CustodyExpensesPage from "../pages/dashboard/collection-finance/CustodyExpensesPage";
import TypesExpensesPage from "../pages/dashboard/collection-finance/TypesExpensesPage";
import AccountsPage from "../pages/dashboard/collection-finance/AccountsPage";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: "/",
      children: [
        {
          element: <Navigate to="auth/welcome-page" replace />,
          index: true,
        },
        { path: "auth/welcome-page", element: <WelcomePage /> },
        { path: "auth/login", element: <LoginPage /> },
        { path: "auth/reset-password", element: <ResetPassword /> },
      ],
    },

    // Dashboard
    {
      path: "dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/overview" replace />, index: true },
        { path: "overview", element: <OverViewPage /> },
        { path: "search-oppontnet", element: <SearchOppontentPage /> },

        //users
        {
          path: "users",
          children: [
            { path: "", element: <UsersPage /> },
            { path: "add-new-user", element: <AddNewUser /> },
          ],
        },

        //collection
        {
          path: "collection",
          children: [
            { path: "", element: <ClientsCollectionPage /> },
            { path: "add-new-dept-client", element: <AddNewDeptClientPage /> },
            { path: "detailes", element: <CollectionPage /> },
            { path: "create-invoice", element: <CreateInvoice /> },
            { path: "upload-file", element: <UploadFilePage /> },
            // { path: "opponent", element: <OpponentPage /> },
            { path: "opponent", element: <OpponentProfilePage /> },
            { path: "create-procedure", element: <CreateProcedures /> },
            { path: "edit-procedure", element: <EditProcedures /> },
            { path: "tasks-procedures", element: <TasksProceduresPage /> },
            { path: "create-template", element: <CreateTemplatePage /> },
            { path: "create-template-table", element: <CreateTemplateTable /> },
          ],
        },

        //personal-profilem
        {
          path: "personal-profile",
          children: [{ path: "", element: <PersonalProfilePage /> }],
        },

        //hr
        {
          path: "hr",
          children: [
            {
              path: "employee",

              children: [
                { path: "", element: <EmployeePage /> },
                { path: "add-new-employee", element: <AddNewEmployee /> },
                { path: "edit-employee", element: <EditEmployee /> },
              ],
            },
          ],
        },

        //collection-finance
        {
          path: "finance",
          children: [
            { path: "", element: <CollectionFinancesPage /> },
            { path: "expense-statement", element: <ExpenseStatementPage /> },
            {
              path: "new-expense-statement",
              element: <NewExpenseStatementPage />,
            },
            {
              path: "custody-expenses",
              element: <CustodyExpensesPage />,
            },
            {
              path: "expenses-types",
              element: <TypesExpensesPage />,
            },
            {
              path: "accounts",
              element: <AccountsPage />,
            },
          ],
        },
      ],
    },

    //

    {
      path: "setup-wizard",
      //element: <DashboardLayout />,
      children: [
        { path: "", element: <OfficeDetails /> },
        { path: "add-new-user", element: <AddNewUserPage /> },
      ],
    },
  ]);
}
