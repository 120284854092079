import React, { useState } from "react";

import {
  Box,
  Typography,
  MenuItem,
  Select,
  Checkbox,
  Button,
} from "@mui/material";

// form
import FormProvider from "../../../../components/hook-form";

import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";

import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@mui/material/FormControl";

import ListItemText from "@mui/material/ListItemText";

import ListItemIcon from "@material-ui/core/ListItemIcon";

import YearCustomDatePicker from "../../../../components/custom-datepicker/YearCustomDatePicker";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";
// -----------------------------------------------------------------------------

//* ------- Styles --------
const useStyles = makeStyles((theme) => ({
  formControl: {
    // width: "250px",
    "& .MuiFormLabel-root": {
      fontSize: "16px",
      boxShadow: "#101828",
    },
    "& .MuiOutlinedInput-root": {
      background: "#fff",
      borderRadius: "5px",
      boxShadow: "#101828",
      height: "44px",
      margin: "0px",
      border: "1px solid #D0D5DD",
      "&:hover fieldset": {
        border: "1px solid #D0D5DD",
        borderColor: "#D0D5DD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
      },
    },
  },
}));

const CustomizedAccordion = styled(Accordion)(() => ({
  width: "160px",
  "&.Mui-expanded": {
    boxShadow: "none",
    padding: 0,
    margin: 0,
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },
  "& .MuiPaper-root-MuiAccordion-root.Mui-expanded": { padding: "0" },

  ".MuiButtonBase-root": {
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    height: "40px",
  },
}));

const boxAccordionTitleStyle = {
  bgcolor: "#FEFEFE",
  color: "#2F2B2C",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
};

const bodyOfAccordionStyle = {
  bgcolor: "#EAF2F5",
  boxShadow: "none",
};

const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
};

const txtFieldBorderStyle = {
  borderRadius: "5px",
  width: "100%",
  boxShadow: "#101828",

  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",

    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

const nameFileStyle = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#FEFEFE",
  lineHeight: "20px",
};

const fileBoxStyle = {
  bgcolor: "#6A8490",
  padding: "10px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "fit-content",
  marginLeft: "10px",
  marginRight: "10px",
  marginBottom: "8px",
  marginTop: "8px",
};

const boxStyle = {
  display: "flex",
  flexDirection: "column",
};

const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#2F2B2C",
  paddingBottom: "4px",
};

const topSaveButtonStyle = {
  mt: "20px",
  mb: "20px",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#6A8490",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "184px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const topCancleButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#344054",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "184px",
  height: "40px",
  p: "10px 18px 10px 18px",
  border: "1px solid #CCDDE4",

  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const defaultValuesButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#D51A52",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "100%",
  mt: "30px",
  height: "40px",
  p: "12px 20px 12px 20px",
  border: "1px solid #CCDDE4",
  boxShadow: "#101828",

  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "250px",
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const options = ["هاني اللحام", "منذر فارس ", "سامر الماهر ", "شادي اللحام"];

//___________________________________________________________
export default function FilterOpponentAccordion() {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const [selected, setSelected] = useState([]);

  const FilterSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(FilterSchema),
    defaultValues,
  });

  const { handleSubmit, setValue } = methods;

  const onSubmit = async (data) => {};

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected(typeof value === "string" ? value.split(",") : value);
  };

  const handleRemove = (fileId) => {
    setSelected((prevItems) => prevItems.filter((item) => item !== fileId));
  };

  // ------------- JSX Code ----------
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ ml: "5px" }}>
          <CustomizedAccordion
            disableGutters
            elevation={0}
            expanded={expanded}
            sx={{
              position: "relative",
              mr: "10px",
              mb: { xs: "10px", sm: "0px", md: "0px" },
            }}
          >
            <AccordionSummary
              onClick={() => {
                setExpanded(!expanded);
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={boxAccordionTitleStyle}
            >
              الفلاتر{" "}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                ...bodyOfAccordionStyle,
                position: "absolute",
                top: 0,
                left: -130,
                mt: "48px",
                zIndex: 1000,
              }}
            >
              <Box sx={boxStyle}>
                <Typography sx={{ ...lableSx, mt: "10px" }}>
                  {" "}
                  الإسناد
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={selected}
                    onChange={handleChange}
                    sx={{
                      ...txtFieldBorderStyle,

                      "& .MuiSelect-select span::before": {
                        content: "' اختر موظفاً'",
                        color: "#697D95",
                        fontWeight: 300,
                        fontSize: "14px",
                      },
                    }}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>None</em>;
                      }

                      return selected
                        ?.map((sub, index) => {
                          return `${sub}`;
                        })
                        .join(", ");
                    }}
                  >
                    {options?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <ListItemIcon>
                          <Checkbox checked={selected.indexOf(name) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={`${name}`} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", maxWidth: "100%", flexWrap: "wrap" }}>
                {selected?.map((select, index) => (
                  <Box key={index} value={select} sx={{ ...fileBoxStyle }}>
                    <Typography sx={nameFileStyle}>{select}</Typography>
                    <img
                      src="/assets/icons/remove.svg"
                      alt=""
                      style={{ paddingRight: "5px" }}
                      onClick={() => handleRemove(select)}
                    />
                  </Box>
                ))}
              </Box>

              <Typography sx={{ ...lableSx, mt: "10px" }}>
                {" "}
                تاريخ الإجراء
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <YearCustomDatePicker
                  name="firstDate"
                  sx={txtFieldBorderStyle}
                  setValue={setValue}
                />
                <Box sx={{ ml: "20px", display: "flex" }}></Box>
                <YearCustomDatePicker
                  name="secondDate"
                  sx={txtFieldBorderStyle}
                  setValue={setValue}
                />
              </Box>

              <Typography sx={{ ...lableSx, mt: "10px" }}>
                {" "}
                تاريخ المراجعة
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <YearCustomDatePicker
                  name="closeDate"
                  sx={txtFieldBorderStyle}
                  setValue={setValue}
                />
                <Box sx={{ ml: "20px", display: "flex" }}></Box>
                <YearCustomDatePicker
                  name="secondCloseDate"
                  sx={txtFieldBorderStyle}
                  setValue={setValue}
                />
              </Box>

              <Button
                fullWidth
                size="large"
                type="submit"
                variant="Contained"
                sx={defaultValuesButtonStyle}
              >
                إزالة الفلاتر
              </Button>

              <Box sx={flexBoxStyle}>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="Contained"
                  sx={{ ...topSaveButtonStyle, mr: "40px" }}
                >
                  تطبيق
                </Button>

                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="Contained"
                  sx={topCancleButtonStyle}
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                >
                  الغاء
                </Button>
              </Box>
            </AccordionDetails>{" "}
          </CustomizedAccordion>
        </Box>
      </FormProvider>
    </>
  );
}
