import { useEffect, useState } from "react";

const useOppontmentSearch = (oppontments) => {
  const [searchTermOppontment, setSearchTermOppontment] = useState("");
  const [filteredOppontment, setFilteredOppontment] = useState([]);

  useEffect(() => {
    setFilteredOppontment(oppontments);
  }, [oppontments]);

  const handleSearchOppontment = (event) => {
    const { value } = event.target;
    setSearchTermOppontment(value.toLowerCase());

    const filteredOppontment = oppontments.filter((oppontment) => {
      const firstName = oppontment?.first_name || "";
      const lastName = oppontment?.last_name || "";
      const fullName = oppontment?.full_name || `${firstName} ${lastName}`;

      return fullName.toLowerCase().includes(value.toLowerCase());
    });

    setFilteredOppontment(filteredOppontment);
  };

  return {
    searchTermOppontment,
    filteredOppontment,
    handleSearchOppontment,
  };
};

export default useOppontmentSearch;
