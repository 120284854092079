import { Grid, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const OppDetailsSkeletion = () => {
  const skeletonStyle = {
    borderRadius: "4px",
    bgcolor: "rgba(0,0,0,0.05)",
    marginBottom: "1em",
  };

  return (
    <Box sx={{ pt: "40px" }}>
      <Grid container columnSpacing={10}>
        <Grid item xs={12} sm={12} md={8}>
          <Skeleton sx={skeletonStyle} variant="rectangular" height={400} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Skeleton sx={skeletonStyle} variant="rectangular" height={400} />
        </Grid>
      </Grid>

      <Grid container columnSpacing={10} mt="30px">

        <Grid item xs={12} sm={12} md={4}>
          <Skeleton sx={skeletonStyle} variant="rectangular" height={200} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OppDetailsSkeletion;
