import React from "react";

import { Box, Modal, Typography, Backdrop, Alert, Button } from "@mui/material";

import FormProvider from "../../../../components/hook-form";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

import { LoadingButton } from "@mui/lab";

// -----------------------------------------------------------------------------

// ----------- Styles --------------

const ModalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",
  width: { xs: "95%", sm: "543px", md: "800px", lg: "548px" },
  bgcolor: "#CCDDE4",
  pt: { xs: "60px", md: "24px", lg: "30px" },
  pb: { xs: "40px", md: "24px", lg: "30px" },
  px: { xs: "10px", sm: "20px", md: "20px", lg: "30px" },
  height: "auto",
};

const txtFieldBorderStyle = {
  width: "100%",
  boxShadow: "#101828",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

const loadingButtonStyle = {
  fontSize: "16px",
  width: "173px",
  height: "48px",
  fontWeight: 600,
  bgcolor: "#97BDCE",
  color: "#2F2B2C",
  p: "10px 18px 10px 18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const modalTitleSx = {
  textAlign: "center",
  fontWeight: 500,
  color: "#2F2B2C",
  fontSize: "24px",
  lineHeight: "36px",
  pb: "10px",
};

const buttonStyle = {
  ml: "10px",
  fontSize: "16px",
  height: "48px",
  width: "173px",
  fontWeight: 400,
  bgcolor: "#FFF",
  color: "#344054",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "18px",
  paddingRight: "18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const boxOfActionButtonStyle = {
  display: "flex",
  justifyContent: "space-between",
  mt: "30px",
};

const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  color: "rgba(37, 42, 49, 1)",
  paddingBottom: "4px",
};

const step2Sx = {
  fontSize: "16px",
  fontWeight: 400,
  color: "rgba(47, 43, 44, 1)",
  paddingBottom: "15px",
};

const boxOfModalStyle = { position: "absolute", top: "22px", left: "22px" };

//_______________________________________________________________

export default function UploadFileModal({
  open,
  onCloseModel,
  setOpenTempTypeModal,
  setfiles,
}) {
  const excelSchema = Yup.object().shape({
    files: Yup.mixed()
      .required("File is required")
      .test("fileType", "يجب إدخال ملفات من نوع إكسل فقط", (value) => {
        if (!value) return false;
        return [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ].includes(value.type);
      }),
  });

  const methods = useForm({
    resolver: yupResolver(excelSchema),
    defaultValues: { files: [] },
  });

  const {
    clearErrors,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    setOpenTempTypeModal(true);
    onCloseModel();
  };

  // ----------- JSX Code -------------
  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModel}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={{ ...ModalSx }}>
          <Box sx={boxOfModalStyle} onClick={onCloseModel}>
            <img src="/assets/icons/x.svg" alt="" style={{}} />
          </Box>

          <Typography sx={modalTitleSx}> رفع ملف</Typography>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
            <Typography
            // sx={stepSx}
            >
              الخطوة 1 من 3
            </Typography>

            <Typography sx={step2Sx}>
              رجاء قم برفع الملف الذي تود إدخاله للنظام
            </Typography>

            <Typography sx={lableSx}>الملف</Typography>

            <input
              name="files"
              type="file"
              InputLabelProps={{ shrink: true }}
              sx={{
                ...txtFieldBorderStyle,
                width: "100%",
                marginRight: "12px",
              }}
              onChange={(event) => {
                setValue("files", event.target.files[0]);
                setfiles(event.target.files[0]);
                clearErrors("files");
              }}
            />
            {errors.files && (
              <Typography my={2} color="error">
                {errors.files.message}
              </Typography>
            )}

            <Box sx={boxOfActionButtonStyle}>
              <LoadingButton
                fullWidth
                disableRipple
                size="large"
                type="submit"
                variant="Contained"
                sx={loadingButtonStyle}
              >
                حفظ
              </LoadingButton>
              <Button
                fullWidth
                disableRipple
                size="large"
                type="button"
                variant="Contained"
                sx={buttonStyle}
                onClick={onCloseModel}
              >
                إلغاء
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}
