import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";

const LoadMoreButton = ({ lastPage, onLoadMore, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleLoadMore = () => {
    if (currentPage < lastPage) {
      let nextPage = currentPage + 1;
      setCurrentPage(nextPage);

      if (onLoadMore) {
        onLoadMore(nextPage);
      }
    }
  };

  return (
    <LoadingButton loading={loading} onClick={handleLoadMore}>
      تحميل المزيد
    </LoadingButton>
  );
};

export default LoadMoreButton;
