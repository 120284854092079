import React, { useState } from "react";

import PropTypes from "prop-types";
// @ Mui
import {
  TableRow,
  TableCell,
  Typography,
  Stack,
  Tooltip,
  IconButton,
  MenuItem,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../../../../components/iconify";
import MenuPopover from "../../../../../components/menu-popover/MenuPopover";
import { toast } from "react-toastify";

// ------------------------------------------------------------------------

const innerCellBorderStyle = {
  borderWidth: "1px 0px",
  borderColor: "#eee",
  borderStyle: "solid",
};

const cellTextSx = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#2F2B2C",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(odd)": {
    backgroundColor: "#CCDDE4",
  },
}));
const menuItemTextStyle = {
  ml: "10px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#2F2B2C",
};
const menuStyle = {
  width: "auto",
  boxShadow: " 2px 2px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  border: "1px solid rgba(103, 103, 103, 0.1)",
  p: "16px 22px 16px 22px",
};
//_______________________________________________________
ExpensesListTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

//___________________________________________________________________
export default function ExpensesListTableRow(props) {
  const { row, selected, onSelectRow } = props;
  const { employeesList } = useSelector((state) => state.users);
  const maxLength = 30;
  const statement = row?.notes || "";

  const isLongStatement = statement.length > maxLength;
  const displayText = isLongStatement
    ? `${statement.substring(0, maxLength)}...`
    : statement;
  const selectedEm = employeesList?.find((o) => o.id === row?.employee);
  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    event.stopPropagation();
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  // -------- JSX Code -------
  return (
    <>
      <StyledTableRow hover selected={selected}>
        <TableCell sx={innerCellBorderStyle}></TableCell>
        <TableCell sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>{row?.expense_obj?.name}</Typography>
          </Stack>
        </TableCell>

        <TableCell sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>
              {row?.cost + " " + row.currency}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="center" sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>{row?.expense_date}</Typography>
          </Stack>
        </TableCell>

        <TableCell align="center" sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>
              {row?.expense_statement}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center" sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            {isLongStatement ? (
              <Tooltip title={statement} placement="top">
                <Typography sx={{ ...cellTextSx }}>{displayText}</Typography>
              </Tooltip>
            ) : (
              <Typography sx={{ ...cellTextSx }}>{displayText}</Typography>
            )}
          </Stack>
        </TableCell>
        {/** ACTIONS*/}
        <TableCell
          onClick={(e) => {
            e.stopPropagation();
          }}
          align="center"
          sx={innerCellBorderStyle}
        >
          <Typography component="div" sx={{ ...cellTextSx }}>
            <IconButton
              color={openPopover ? "inherit" : "default"}
              onClick={handleOpenPopover}
            >
              <Iconify icon="eva:more-horizontal-fill" />
            </IconButton>
          </Typography>
        </TableCell>

        <MenuPopover
          open={openPopover}
          onClose={handleClosePopover}
          arrow="top-right"
          sx={menuStyle}
        >
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              if (row?.attachments?.length === 0) {
                toast.success("لا يوجد مرفقات");
              } else {
                row?.attachments?.forEach((attachment) => {
                  window.open(attachment.file, "_blank");
                });
              }
            }}
          >
            <img alt="" disabledEffect src="/assets/icons/eye.svg" />
            <Typography sx={{ ...menuItemTextStyle }}>عرض المرفق</Typography>
          </MenuItem>

          {/**************************************************************** */}
        </MenuPopover>
      </StyledTableRow>
    </>
  );
}
