import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";

import {
  useTable,
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TableNoData,
  TablePaginationCustom,
} from "../../../../../components/table";

import { IconButton, Tooltip } from "@mui/material";

import Iconify from "../../../../../components/iconify";

import Scrollbar from "../../../../../components/scrollbar";

import EmployeeTableRow from "./EmployeeTableRow";

import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { empsColumnsList } from "../../../../../utils/columnsLists";
import { getEmployeeService } from "../../../../../redux/services/debt_collection_service";

// ----------------------------------------------------------------------

// --------- Style ------------

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  height: "auto",
  overflow: "unset",
  borderColor: theme.palette.primary.main,
  border: "1.99262px 1.99262px 9.9631px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  background: "#fff",
}));

const tableHolderSx = {
  minWidth: 800,
};

export default function EmployeeList() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { employees, isLoading, empCount } = useSelector(
    (state) => state.deptCollection
  );
  const selectedColumns = empsColumnsList.filter((item) => item.status);
  const TABLE_HEAD = [
    ...selectedColumns.map((item) => ({
      id: item.id,
      label: item.name,
      align: "center",
    })),
    { id: "", label: "", align: "center" },
  ];

  const {
    dense,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSelectRow,
    onSelectAllRows,
    onSort,
  } = useTable();
  //

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(employees);
  }, [dispatch, employees]);

  const denseHeight = dense ? 34 : 54;

  const handleChange = (event, value) => {
    dispatch(getEmployeeService({ page: value }));
    setPage(value)
  };

  return (
    <>
      <StyledTableContainer>
        <TableSelectedAction
          dense={dense}
          numSelected={selected?.length}
          rowCount={tableData?.length}
          onSelectAllRows={(checked) =>
            onSelectAllRows(
              checked,
              tableData?.map((row) => row?.id)
            )
          }
          action={
            <Tooltip title="حذف">
              <IconButton color="#FFFFF">
                <Iconify icon="eva:trash-2-outline" />
              </IconButton>
            </Tooltip>
          }
        />{" "}
        <Scrollbar>
          <Table size={dense ? "small" : "medium"} sx={{ ...tableHolderSx }}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              numSelected={selected.length}
              onSort={onSort}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row) => row?.id)
                )
              }
            />

            <TableBody>
              {!isLoading && (
                <>
                  {tableData?.map((row) => (
                    <EmployeeTableRow
                      key={row.id}
                      row={row}
                      onSelectRow={() => onSelectRow(row.id)}
                      selected={selected}
                      selectedColumns={selectedColumns}
                    />
                  ))}
                </>
              )}
              {!isLoading && tableData?.length === 0 && (
                <TableNoData isNotFound />
              )}
              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(page, rowsPerPage, tableData?.length)}
              />
            </TableBody>
          </Table>
        </Scrollbar>
        <TablePaginationCustom
          count={empCount}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChange={handleChange}
        />
      </StyledTableContainer>
    </>
  );
}
