import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// POST =>  CREATE USER
export const createEmployeeService = createAsyncThunk(
  "users/createEmployeeService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createEmployeeURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// POST =>  UPDATE USER
export const updateEmployeeService = createAsyncThunk(
  "users/updateEmployeeService",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await axios.put(`${URLS.employees}/${id}/update`, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET USER
export const getEmployeeService = createAsyncThunk(
  "users/getEmployee",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getEmployeesURL);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// GET =>  GET USER
export const getEmployeeServiceList = createAsyncThunk(
  "users/getEmployeeServiceList",
  async ({ page }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.getEmployeeServiceList}?page=${page}`
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET GROUP
export const getGroupService = createAsyncThunk(
  "users/getGroup",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.gitListGroupWithEmployeeURL);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET Users Non Employee
export const getUsersNonEmployeeService = createAsyncThunk(
  "users/getUsersNonEmployee",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getListEmployeesWithoutGroupURL);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  POST GROUP
export const createGroupService = createAsyncThunk(
  "users/createGroup",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.addGroupURL, data);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  REmove GROUP
export const removeUserFromGroupService = createAsyncThunk(
  "users/createGroup",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.removeUserFromGroupURL, data);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  get Employee SideBar

export const getEmployeeSideBarService = createAsyncThunk(
  "users/getEmployeeSideBarService",
  async (client_id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.getEmployeeSideBar}/${client_id}`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  get Employee SideBar

export const requestVacationService = createAsyncThunk(
  "users/requestVacationService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.vacationRequestsURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// GET =>  GET ALL VACATIONS

export const getAllVacations = createAsyncThunk(
  "users/getAllVacations",
  async (thunkAPI) => {
    try {
      const response = await axios.get(`/vacation-requests`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// GET =>  GET Pending VACATIONS
export const getPendingVacations = createAsyncThunk(
  "users/getPendingVacations",
  async (thunkAPI) => {
    try {
      const response = await axios.get(`/vacation-requests/pending/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET Pending VACATIONS
export const vacationReq = createAsyncThunk(
  "users/vacationReq",
  async ({ id, status }, thunkAPI) => {
    try {
      const response = await axios.put(`/vacation-requests/${id}/status/`, {
        status,
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
