import React, { useState, useEffect } from "react";

import {
  Box,
  Typography,
  Grid,
  MenuItem,
  Button,
  Tab,
  Tabs,
} from "@mui/material";

// form
import { RHFTextField, RHFSelect } from "../../../../components/hook-form";

import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";

import { useDispatch, useSelector } from "react-redux";

import useClientSearch from "../../../../hooks/useClientSearch";

import { getAllClientsService } from "../../../../redux/services/general_service";
import { exportAssignmentService } from "../../../../redux/services/debt_collection_service";

import YearCustomDatePicker from "../../../../components/custom-datepicker/YearCustomDatePicker";

import ProceduresTab from "../tabs/opponent-tab/ProceduresTab";

// -----------------------------------------------------------------------------

//* ------- Styles --------

const CustomizedAccordion = styled(Accordion)(() => ({
  "&.Mui-expanded": {
    boxShadow: "none",
    margin: "0px",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    //margin: 0,
  },
  ".MuiButtonBase-root": {
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

const boxAccordionTitleStyle = {
  bgcolor: "#6A8490",
  color: "#FEFEFE",
  fontSize: "22px",
  fontWeight: 500,
};
const styleTabs = {
  "& button.Mui-selected": {
    background: "#97BDCE",
    p: "10px 16px 10px 16px",
    color: "#FEFEFE",
    border: "0px 0px 2px 0px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "28px",
    minHeight: "40px",
  },
  bgcolor: "#F5F5F5",
  "& .MuiTabs-flexContainer": {
    justifyContent: {
      sm: "flex-star",
      md: "flex-star",
      lg: "flex-start",
      xs: "flex-start",
    },
    display: "flex",
    alignItems: "center",
  },
  "& .MuiTabs-scroller": {
    display: "flex",
    alignItems: "center",
  },
  "& .Mui-selected": {},

  "& .MuiButtonBase-root:not(.Mui-selected)": {
    color: " #697D95",
    fontSize: "14px",
    fontWeight: 400,
    minHeight: "40px",
    lineHeight: "20px",
  },
};
const bodyOfAccordionStyle = {
  bgcolor: "#CCDDE4",
  paddingTop: "20px",
};
const styleClickableTab = {
  borderRadius: "8px",
  minWidth: "134px",
};
const lableStyle = {
  fontSize: "14px",
  fontWeight: 500,
  color: "rgba(47, 43, 44, 1)",
  pb: "8px",
};
const styleTab = {
  minHeight: "35px",
  minWidth: "134px",
};

const topSaveButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#6A8490",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};
const MainSaveButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#97BDCE",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};
const tabBoxStyle = {
  height: "40px",
  display: "flex",
  alignItems: "center",
  mt: "4px",
  mb: "10px",
};
const txtFieldBorderStyle = {
  borderRadius: "5px",
  width: "100%",
  boxShadow: "#101828",
  mb: "8px",

  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",

    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};
const bigFlexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const dateNameStyle = {
  fontSize: "16px",
  fontWeight: 600,
  color: "rgba(47, 43, 44, 1)",
  lineHeight: "28px",
};

const dateValueStyle = {
  fontSize: "16px",
  fontWeight: 400,
  color: "rgba(47, 43, 44, 1)",
  lineHeight: "24px",
  ml: "5px",
};
const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
  mt: { xs: "15px", sm: "0px" },
};
const AccflexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  mt: { xs: "15px", sm: "0px" },
};
const loadingButtonStyle = {
  borderRadius: "5px",
  width: "268px",
  fontSize: "16px",
  height: "40px",
  fontWeight: 400,
  //bgcolor: "#97BDCE",
  color: "#6A8490",
  border: "2px solid #6A8490",
  paddingLeft: "18px",
  paddingright: "18px",
  paddingTop: "10px",
  paddingBottom: "10px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};
const topCancleButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#2F2B2C",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "160px",
  height: "40px",
  p: "12px 20px 12px 20px",
  border: "1px solid #CCDDE4",
  marginLeft: "20px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

//___________________________________________________________
export default function ProceduresAccordion({
  setValue,
  control,
  errors,
  reset,
}) {
  const { oppTab } = useSelector((state) => state.general);
  const [currentTab, setCurrentTab] = useState(oppTab);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedFileStatus, setSelectedFileStatus] = useState({});

  const [selectedAction, setSelectedActionStage] = useState({});

  const [selectedCourt, setSelectedCourt] = useState({});

  const { clients } = useSelector((state) => state.general);

  const { courts } = useSelector((state) => state.matter);

  const { filteredClients, searchTerm, handleSearch } =
    useClientSearch(clients);

  const [selectedClient, setSelectedClient] = useState({});
  const [expanded, setIsExpanded] = useState(true);

  const { OppDetails, fileStatus, actionStage, opponentId, proceduresTypes } =
    useSelector((state) => state?.deptCollection);
  const file_status = fileStatus?.map((item) => ({
    value: item[0],
    label: item[1],
  }));
  const action_stage = actionStage?.map((item) => ({
    value: item[0],
    label: item[1],
  }));

  const handleExportAssignment = () => {
    dispatch(exportAssignmentService({ id: opponentId }));
  };

  useEffect(() => {
    dispatch(getAllClientsService({ page: 1 }));
  }, []);
  useEffect(() => {
    setSelectedClient(
      filteredClients?.find((cl) => cl.id == OppDetails?.client)
    );
    setSelectedCourt(courts?.find((c) => c.id == OppDetails?.execution_court));
    setSelectedFileStatus(OppDetails?.file_status);
    setSelectedActionStage(OppDetails?.action_stage);
  }, [OppDetails, filteredClients]);

  // ------------- JSX Code ----------
  return (
    <>
      <CustomizedAccordion expanded={expanded}>
        <AccordionSummary
          // onClick={() => {
          //   setIsExpanded(!expanded);
          // }}
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={boxAccordionTitleStyle}
        >
          <Box
            sx={{
              // width: "100%",
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={boxAccordionTitleStyle}> الاجراءات</Typography>
            {OppDetails?.file_status !== "سداد نهائي" && (
              <Box
                onClick={() =>
                  navigate("/dashboard/collection/create-procedure")
                }
              >
                <img
                  src="/assets/icons/plus_white.svg"
                  alt=""
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                />
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={bodyOfAccordionStyle}>
          <ProceduresTab />
        </AccordionDetails>
      </CustomizedAccordion>
    </>
  );
}
