import React from "react";

// @mui
import { Box } from "@mui/material";
import ProceduresList from "../../table/procedures-list";

// -----------------------------------------------------------------------------

//* ------- Styles --------

//_______________________________________________-
export default function TelecomTab({ currentTab }) {
  // ------------- JSX Code ----------
  return (
    <>
      <Box sx={{ mt: "20px" }}>
        <ProceduresList currentTab={currentTab} />
      </Box>
    </>
  );
}
