import { Suspense, lazy } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(
  lazy(() => import("../pages/auth/login/LoginPage"))
);

export const WelcomePage = Loadable(
  lazy(() => import("../pages/auth/welcome/WelcomePage"))
);

export const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/reset-password/ResetPassword"))
);

//Office Details
export const OfficeDetails = Loadable(
  lazy(() => import("../pages/setup-wizard/OfficeDetails"))
);

// Add New User Page
export const AddNewUserPage = Loadable(
  lazy(() => import("../pages/setup-wizard/AddNewUserPage"))
);

//ClientsPage
export const ClientsPage = Loadable(
  lazy(() => import("../pages/dashboard/clients-section/ClientsPage"))
);

//AddNewUser
export const AddNewUser = Loadable(
  lazy(() => import("../pages/dashboard/users/AddNewUser"))
);

//OverViewPage

export const OverViewPage = Loadable(
  lazy(() => import("../pages/dashboard/overview/OverViewPage"))
);

// CollectionPage
export const CollectionPage = Loadable(
  lazy(() => import("../pages/dashboard/collection/CollectionPage"))
);

// CollectionPage
export const ClientsCollectionPage = Loadable(
  lazy(() => import("../pages/dashboard/collection/ClientsCollectionsPage"))
);

// CollectionPage
export const AddNewDeptClientPage = Loadable(
  lazy(() => import("../pages/dashboard/collection/AddNewDeptClientPage"))
);

//CreateInvoice
export const CreateInvoice = Loadable(
  lazy(() => import("../pages/dashboard/collection/CreateInvoice"))
);

//UploadFilePage
export const UploadFilePage = Loadable(
  lazy(() => import("../pages/dashboard/collection/UploadFilePage"))
);

//OpponentPage
export const OpponentPage = Loadable(
  lazy(() => import("../pages/dashboard/collection/OpponentPage"))
);

//CreateProcedures
export const CreateProcedures = Loadable(
  lazy(() => import("../pages/dashboard/collection/CreateProcedures"))
);

//EmployeePage
export const EmployeePage = Loadable(
  lazy(() => import("../pages/dashboard/employee/EmployeePage"))
);

//AddNewEmployee
export const AddNewEmployee = Loadable(
  lazy(() => import("../pages/dashboard/employee/AddNewEmployee"))
);

//EditEmployee
export const EditEmployee = Loadable(
  lazy(() => import("../pages/dashboard/employee/EditEmployee"))
);

//EditProcedures
export const EditProcedures = Loadable(
  lazy(() => import("../pages/dashboard/collection/EditProcedures"))
);

//TasksProceduresPage
export const TasksProceduresPage = Loadable(
  lazy(() => import("../pages/dashboard/collection/TasksProceduresPage"))
);

//PersonalProfilePage
export const PersonalProfilePage = Loadable(
  lazy(() => import("../pages/dashboard/personal-profile/PersonalProfilePage"))
);

//VacationsPage
export const VacationsPage = Loadable(
  lazy(() => import("../pages/dashboard/employee/VacationsPage"))
);

//CollectionFinancesPage
export const CollectionFinancesPage = Loadable(
  lazy(() =>
    import("../pages/dashboard/collection-finance/CollectionFinancesPage")
  )
);

//ExpenseStatementPage
export const ExpenseStatementPage = Loadable(
  lazy(() =>
    import("../pages/dashboard/collection-finance/ExpenseStatementPage")
  )
);

//NewExpenseStatementPage
export const NewExpenseStatementPage = Loadable(
  lazy(() =>
    import("../pages/dashboard/collection-finance/NewExpenseStatementPage")
  )
);
//NewExpenseStatementPage
export const TypesExpensesPage = Loadable(
  lazy(() => import("../pages/dashboard/collection-finance/TypesExpensesPage"))
);

//CreateTemplatePage
export const CreateTemplatePage = Loadable(
  lazy(() => import("../pages/dashboard/collection/CreateTemplatePage"))
);

//CreateTemplatePage
export const CreateTemplateTable = Loadable(
  lazy(() => import("../pages/dashboard/collection/CreateTemplateTable"))
);

//SearchOppontent
export const SearchOppontentPage = Loadable(
  lazy(() => import("../pages/dashboard/oppontent/SearchOppontentPage"))
);
//CreateStetment
export const AccountsPage = Loadable(
  lazy(() => import("../pages/dashboard/collection-finance/AccountsPage"))
);
