import React from "react";

export default function Calender() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33062 6.61447H15.4494M4.87448 1.0001V2.8317M4.87448 2.8317L15.4992 2.8315M4.87448 2.8317C3.11407 2.8317 1.68714 4.28333 1.68722 6.07419L1.68772 16.8826C1.6878 18.6734 3.11483 20.125 4.87512 20.125H15.4998C17.2602 20.125 18.6873 18.6732 18.6872 16.8823L18.6867 6.07389C18.6866 4.28314 17.2595 2.8315 15.4992 2.8315M15.4992 1V2.8315M8.06268 16.342V9.85694L5.93774 11.4782M13.9063 16.342V9.85694L11.7813 11.4782"
        stroke="#2F2B2C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
