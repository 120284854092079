import React, { useState } from "react";

import { Box, Typography, Checkbox, Button } from "@mui/material";

// form

import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";

import { useDispatch } from "react-redux";

import { usersColumnsList } from "../../../../utils/columnsLists";

import { setUsersColumnsList } from "../../../../redux/slices/users";

// -----------------------------------------------------------------------------

//* ------- Styles --------

const CustomizedAccordion = styled(Accordion)(() => ({
  width: "160px",
  "&.Mui-expanded": {
    boxShadow: "none",
    padding: 0,
    margin: 0,
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },
  "& .MuiPaper-root-MuiAccordion-root.Mui-expanded": { padding: "0" },

  ".MuiButtonBase-root": {
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    height: "40px",
  },
}));

const boxAccordionTitleStyle = {
  bgcolor: "#FEFEFE",
  color: "#2F2B2C",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
};

const bodyOfAccordionStyle = {
  bgcolor: "#EAF2F5",
  boxShadow: "none",
};

const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
};

const topSaveButtonStyle = {
  mt: "20px",
  mb: "20px",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#6A8490",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "184px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const topCancleButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#344054",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "184px",
  height: "40px",
  p: "10px 18px 10px 18px",
  border: "1px solid #CCDDE4",

  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const columnFlexBoxStyle = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

const checkBoxSx = {
  color: "#6A8490",
  "&.Mui-checked": {
    color: "#6A8490",
  },
};

const caseNameStyle = {
  fontSize: "16px",
  fontWeight: 400,
  color: "#2F2B2C",
  lineHeight: "24px",
};

//___________________________________________________________
export default function ColumnsAccordionUsers() {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);

  const [menuList, setMeunList] = useState(usersColumnsList);

  const handleChange = (id) => {
    setMeunList((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, status: !item.status } : item
      )
    );
  };
  const applyColumnVisible = () => {
    dispatch(setUsersColumnsList(menuList));
    setExpanded(false);
  };
  // ------------- JSX Code ----------
  return (
    <>
      <Box sx={{ ml: "5px" }}>
        {" "}
        <CustomizedAccordion
          disableGutters
          elevation={0}
          expanded={expanded}
          sx={{
            position: "relative",
            mr: "10px",
            mb: { xs: "10px", sm: "0px", md: "0px" },
          }}
        >
          <AccordionSummary
            onClick={() => {
              setExpanded(!expanded);
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              ...boxAccordionTitleStyle,
            }}
          >
            الأعمدة الظاهرة
          </AccordionSummary>
          <AccordionDetails
            sx={{
              ...bodyOfAccordionStyle,
              position: "absolute",
              top: 0,
              left: 0,
              mt: "48px",
              zIndex: 1000,
            }}
          >
            {menuList?.map((caseIssue, index) => (
              <Box
                sx={{
                  ...flexBoxStyle,
                }}
                key={""}
              >
                <Checkbox
                  checked={caseIssue.status}
                  onChange={() => handleChange(caseIssue.id)}
                  sx={{ ...checkBoxSx }}
                />
                <Typography sx={{ ...caseNameStyle }}>
                  {caseIssue.name}
                </Typography>
              </Box>
            ))}
            <Box sx={columnFlexBoxStyle}>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="Contained"
                sx={topSaveButtonStyle}
                onClick={applyColumnVisible}
              >
                تطبيق
              </Button>
              {/** */}
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="Contained"
                sx={topCancleButtonStyle}
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                الغاء
              </Button>
            </Box>
          </AccordionDetails>{" "}
        </CustomizedAccordion>
      </Box>
    </>
  );
}
