import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  STATISTICS
export const getStatisticService = createAsyncThunk(
  "statistic/getStatisticService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getStatistics);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
