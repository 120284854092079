import PropTypes from "prop-types";
// @mui
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from "@mui/material";

// ----------------------------------------------------------------------
const styleBoxStyle = { position: "absolute", top: "22px", left: "22px" };

const flexBoxColumn = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingTop: "30px",
};

const contentStyle = {
  pb: "16px",
  color: "#4F5E71",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
};

const titleStyle = {
  pb: "16px",
  color: "#252A31",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "20px",
};

CustomeDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
};

//______________________________________________________________________
export default function CustomeDialog({
  title,
  content,
  action,
  open,
  onClose,
  body,
  ...other
}) {
  return (
    <Dialog
      fullWidth
      height="336px"
      maxWidth="xs"
      open={open}
      onClose={onClose}
      {...other}
    >
      <Box sx={styleBoxStyle}>
        <img
          alt=""
          src="/assets/icons/x_close.svg"
          sx={{ cursor: "pointer" }}
          onClick={onClose}
        />
      </Box>
      <Box sx={flexBoxColumn}>
        <img
          alt=""
          src="/assets/icons/big_delete.svg"
          sx={{ height: "92px", width: "106px" }}
        />

        <DialogTitle sx={titleStyle}>{title}</DialogTitle>

        {content && <DialogContent sx={contentStyle}>{content}</DialogContent>}

        {body && <DialogContent sx={{ ...contentStyle }}>{body}</DialogContent>}
      </Box>

      <DialogActions>{action}</DialogActions>
    </Dialog>
  );
}
