import React, { useState, useEffect } from "react";

import { Box, Typography, Grid, Tab, Tabs } from "@mui/material";

import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";

import { styled } from "@mui/material/styles";

import { useDispatch } from "react-redux";

import { getAllClientsService } from "../../../../redux/services/general_service";

import { setOpponentTab } from "../../../../redux/slices/general";

import ExpensesList from "../table/ExpensesList";

import PaymentList from "../table/Payment-list";

import AddNewPaymentModal from "../modal/AddNewPaymentModal";

// -----------------------------------------------------------------------------

//* ------- Styles --------

const TABS = [
  {
    value: "0",
    label: "المصروفات",
    component: <ExpensesList />,
  },

  {
    value: "1",
    label: "السداد",
    component: <PaymentList />,
  },
];

const CustomizedAccordion = styled(Accordion)(() => ({
  "&.Mui-expanded": {
    boxShadow: "none",
    margin: "0px",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    //margin: 0,
  },
  ".MuiButtonBase-root": {
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

const boxAccordionTitleStyle = {
  bgcolor: "#6A8490",
  color: "#FEFEFE",
  fontSize: "22px",
  fontWeight: 500,
};

const styleTabs = {
  //border: "0.5px solid #697D95",
  minHeight: "40px",
  bgcolor: "#F5F5F5",
  //
  "& button.Mui-selected": {
    background: "#6A8490",
    p: "0px 24px 0px 24px",
    color: "#FEFEFE",
    //border: "0.5px solid #697D95",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "28px",
    // minHeight: "36px",
    minWidth: "78px",
  },

  "& .MuiTabs-flexContainer": {
    justifyContent: {
      sm: "flex-star",
      md: "flex-star",
      lg: "flex-start",
      xs: "flex-start",
    },
    display: "flex",
    alignItems: "center",
  },
  "& .MuiTabs-scroller": {
    display: "flex",
    alignItems: "center",
  },
  "& .Mui-selected": {},

  "& .MuiButtonBase-root:not(.Mui-selected)": {
    color: "rgba(106, 132, 144, 1)",
    fontSize: "14px",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    p: "0px 24px 0px 24px",
    lineHeight: "24px",
    //border: "0.5px solid rgba(105, 125, 149, 1)",
    // width: "78px",
  },
};

const styleClickableTab = {
  borderRadius: "8px",
  minWidth: "134px",
};

const styleTab = {
  minHeight: "35px",
  minWidth: "134px",
};

const bodyOfAccordionStyle = {
  bgcolor: "#CCDDE4",
  paddingTop: "20px",
};

const tabBoxStyle = {
  height: "40px",
  display: "flex",
  alignItems: "center",
  mt: "4px",
  mb: "10px",
  bgColor: "red",
};

//___________________________________________________________
export default function FileFinances() {
  const [currentTab, setCurrentTab] = useState("0");

  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(null);
  };

  const dispatch = useDispatch();

  const [expanded, setIsExpanded] = useState(true);

  useEffect(() => {
    dispatch(getAllClientsService({ page: 1 }));
  }, []);

  // ------------- JSX Code ----------
  return (
    <>
      <CustomizedAccordion expanded={expanded}>
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          sx={boxAccordionTitleStyle}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography sx={boxAccordionTitleStyle}>ماليات الملف</Typography>

            {currentTab === "1" && (
              <Box onClick={() => setOpenModal(true)}>
                <img
                  src="/assets/icons/plus_white.svg"
                  alt=""
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                />
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={bodyOfAccordionStyle}>
          {/** */}
          <Grid container paddingTop={"10px"}>
            {/** Tabs */}
            <Box
              sx={{
                background: "rgba(234, 242, 245, 1)",
                display: "flex",
                alignItems: "center",
                p: "15px",
                width: "100%",
                mb: "20px",
              }}
            >
              <Box sx={tabBoxStyle}>
                <Tabs
                  value={currentTab}
                  onChange={(event, newValue) => {
                    setCurrentTab(newValue);
                    dispatch(setOpponentTab(newValue));
                  }}
                  sx={styleTabs}
                  TabIndicatorProps={
                    {
                      // style: { display: "none" },
                    }
                  }
                >
                  {TABS.map((tab) => (
                    <Tab
                      sx={
                        tab.value === currentTab
                          ? {
                              styleClickableTab,
                            }
                          : { styleTab }
                      }
                      key={tab.value}
                      value={tab.value}
                      label={tab.label}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
          </Grid>

          {TABS.map(
            (tab) =>
              tab.value === currentTab && (
                <Box key={tab.value}>{tab.component}</Box>
              )
          )}
        </AccordionDetails>
      </CustomizedAccordion>

      {openModal && (
        <AddNewPaymentModal open={openModal} onCloseModel={handleClose} />
      )}
    </>
  );
}
