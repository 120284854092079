import React, { useState } from "react";

import PropTypes from "prop-types";
// @ Mui
import {
  TableRow,
  TableCell,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import Iconify from "../../../../../components/iconify";

import MenuPopover from "../../../../../components/menu-popover";
import { useSelector } from "react-redux";

// ------------------------------------------------------------------------

const innerCellBorderStyle = {
  borderWidth: "1px 0px",
  borderColor: "#eee",
  borderStyle: "solid",
};

const cellTextSx = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#2F2B2C",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(odd)": {
    backgroundColor: "#CCDDE4",
  },
}));

const menuStyle = {
  width: "auto",
  boxShadow: " 2px 2px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  border: "1px solid rgba(103, 103, 103, 0.1)",
  p: "16px 22px 16px 22px",
};

//_______________________________________________________
PaymentTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

//___________________________________________________________________
export default function PaymentTableRow(props) {
  const { row, selected } = props;
  const { employees } = useSelector((state) => state.deptCollection);
  const selectedEm = employees?.find((em) => em.id === row.employee);
  console.log("selectedEm",selectedEm);
  
  const [openPopover, setOpenPopover] = useState(null);

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleOpenPopover = (event) => {
    event.stopPropagation();
    setOpenPopover(event.currentTarget);
  };

  // -------- JSX Code -------
  return (
    <>
      <StyledTableRow hover selected={selected}>
        <TableCell sx={innerCellBorderStyle}></TableCell>

        {/** نوع السداد */}
        <TableCell sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>{row?.type}</Typography>
          </Stack>
        </TableCell>

        {/**  القيمة */}
        <TableCell sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>{row?.value}</Typography>
          </Stack>
        </TableCell>

        {/**  طريقة السداد */}
        <TableCell sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>{row?.method}</Typography>
          </Stack>
        </TableCell>

        {/** */}
        <TableCell sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>{row?.payment_date}</Typography>
          </Stack>
        </TableCell>

        {/**   اسم الموظف */}
        <TableCell sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>
              {selectedEm?.first_name + " " + selectedEm?.last_name}
            </Typography>
          </Stack>
        </TableCell>

        {/** ACTIONS*/}
        <TableCell
          onClick={(e) => {
            e.stopPropagation();
          }}
          align="center"
          sx={innerCellBorderStyle}
        >
          <Typography component="div" sx={{ ...cellTextSx }}>
            <IconButton
              color={openPopover ? "inherit" : "default"}
              onClick={handleOpenPopover}
            >
              <Iconify icon="eva:more-horizontal-fill" />
            </IconButton>
          </Typography>
        </TableCell>

        {/* <MenuPopover
          open={openPopover}
          onClose={handleClosePopover}
          arrow="top-right"
          sx={menuStyle}
        ></MenuPopover> */}
      </StyledTableRow>
    </>
  );
}
