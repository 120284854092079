import { useEffect, useState } from "react";

const useClientSearch = (clients) => {
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredClients, setFilteredClients] = useState(clients);

  useEffect(() => {
    setFilteredClients(clients);
  }, [clients]);

  const handleSearch = (event) => {
    const { value } = event.target;

    setSearchTerm(value);

    const filtered =
      Array.isArray(clients) &&
      clients?.filter((client) => {
        const fullName = `${client.user.first_name} ${client.user.last_name}`;

        return fullName.toLowerCase().includes(value.toLowerCase());
      });

    setFilteredClients(filtered);
  };

  return {
    searchTerm,

    filteredClients,

    handleSearch,
  };
};

export default useClientSearch;
