import React, { useEffect, useState } from "react";

import {
  Box,
  Modal,
  Typography,
  Backdrop,
  Alert,
  MenuItem,
  Grid,
  Button,
} from "@mui/material";

import FormProvider, {
  RHFTextField,
  RHFSelect,
} from "../../../../components/hook-form";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

import { LoadingButton } from "@mui/lab";

import { useDispatch, useSelector } from "react-redux";

import { getAllCurrencies } from "../../../../redux/services/general_service";

import {
  createExpensesCustudy,
  getCustudyExpenseService,
  getEmployeeService,
} from "../../../../redux/services/debt_collection_service";
import LoadMoreButton from "../../../../utils/LoadMoreButton";

// -----------------------------------------------------------------------------

// ----------- Styles --------------

const ModalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",
  width: { xs: "95%", sm: "543px", md: "800px", lg: "600px" },
  bgcolor: "#CCDDE4",
  pt: { xs: "60px", md: "24px", lg: "30px" },
  pb: { xs: "40px", md: "24px", lg: "30px" },
  px: { xs: "10px", sm: "20px", md: "20px", lg: "30px" },
  height: "auto",
};

const txtFieldBorderStyle = {
  borderRadius: "5px",
  width: "100%",
  boxShadow: "#101828",

  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",

    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

const multiRowTxtFieldBorderStyle = {
  borderRadius: "5px",
  width: "100%",
  boxShadow: "#101828",

  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",

    border: "1px solid #D0D5DD",

    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

const loadingButtonStyle = {
  fontSize: "16px",
  width: "173px",
  height: "48px",
  fontWeight: 600,
  bgcolor: "#97BDCE",
  color: "#2F2B2C",
  p: "10px 18px 10px 18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const modalTitleSx = {
  textAlign: "center",
  fontWeight: 500,
  color: "#2F2B2C",
  fontSize: "24px",
  lineHeight: "36px",
  pb: "10px",
};

const buttonStyle = {
  ml: "10px",
  fontSize: "16px",
  height: "48px",
  width: "173px",
  fontWeight: 400,
  bgcolor: "#FFF",
  color: "#344054",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "18px",
  paddingRight: "18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const boxOfActionButtonStyle = {
  display: "flex",
  justifyContent: "space-between",
  mt: "30px",
};

const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#2F2B2C",
  paddingBottom: "4px",
  paddingTop: "10px",
};

const boxOfModalStyle = { position: "absolute", top: "22px", left: "22px" };

const transparentBorderTxtField = {
  "& .MuiOutlinedInput-input": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#2F2B2C",
  },
  "& .MuiInputLabel-root": {
    color: "#2F2B2C",
    fontWeight: 400,
  },
  "& .MuiOutlinedInput-root": {
    height: "42px",
    borderColor: "transparent!important",
    "& fieldset": {
      borderColor: "transparent!important",
    },
    "&:hover fieldset": {
      borderColor: "transparent!important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent!important",
    },
  },
};

//_______________________________________________________________

export default function CreateCustodyExpenseModal({ open, onCloseModel }) {
  const dispatch = useDispatch();
  const {
    employees,
    isLoadingEmployees,
    empLastPage,
    empNext,
    isLoadingCreate,
  } = useSelector((state) => state.deptCollection);

  // ==================================================================
  // EMPLOYESS PAGINATION LIST
  const [employeesList, setEmployeesList] = useState(employees || []);
  useEffect(() => {
    if (employeesList.length === 0 && employees?.length > 0) {
      setEmployeesList(employees);
    }
  }, [employees]);
  const handleLoadMoreEmployees = (nextPage) => {
    dispatch(getEmployeeService({ page: nextPage })).then((res) => {
      setEmployeesList((prev) => [...prev, ...res.payload.data.data]);
    });
  };
  useEffect(() => {
    dispatch(getEmployeeService({ page: 1 })).then((res) => {
      setEmployeesList((prev) => [...prev, ...res.payload.data.data]);
    });
  }, []);
  // ==================================================================
  const [selectedOpponents, setSelectedOpponents] = useState([]);

  const defaultValues = {
    statement: "",
    value: "",
    employee: null,
  };

  const methods = useForm({
    // resolver: yupResolver(CreateLegalExpenseScheema),
    defaultValues,
  });

  const {
    control,
    reset,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    console.log("data", data);
    const formData = new FormData();

    formData.append("statement", data.statement);
    formData.append("value", data.value);
    formData.append("employee", data.employee);
    dispatch(createExpensesCustudy({ data: formData })).then((action) => {
      if (createExpensesCustudy.fulfilled.match(action)) {
        onCloseModel();
        dispatch(
          getCustudyExpenseService({
            id: data.employee,
          })
        );
      }
    });
  };

  // ----------- JSX Code -------------
  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModel}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={{ ...ModalSx }}>
          <Box sx={boxOfModalStyle} onClick={onCloseModel}>
            <img src="/assets/icons/x.svg" alt="" style={{}} />
          </Box>

          <Typography sx={modalTitleSx}>صرف عهدة </Typography>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}

            <Grid container columnSpacing={4} mt="10px">
              <Grid item xs={12}>
                <Typography sx={lableSx}> الموظف</Typography>

                <RHFSelect
                  name="employee"
                  control={control}
                  sx={{
                    ...txtFieldBorderStyle,
                    "& .MuiSelect-select span::before": {
                      content: "'أدخل اسم الموظف '",

                      color: "#697D95",
                      fontWeight: 300,
                      fontSize: "14px",
                    },
                  }}
                  SelectProps={{
                    // native: false,
                    IconComponent: () => (
                      <img
                        src="/assets/icons/Icon.svg"
                        alt=""
                        style={{
                          marginRight: "19px",
                          marginLeft: "19px",
                        }}
                      />
                    ),
                  }}
                  displayEmpty
                  value={selectedOpponents}
                  onChange={(e) => {
                    setValue("employee", e.target.value);
                    setSelectedOpponents(e.target.value);
                  }}
                >
                  {employeesList?.map((em, index) => (
                    <MenuItem
                      sx={{
                        borderBottom: "1px solid  #E5E0EB",
                      }}
                      key={index}
                      value={em.id}
                    >
                      {em?.first_name + " " + em?.last_name}
                    </MenuItem>
                  ))}
                  {empNext && (
                    <LoadMoreButton
                      lastPage={empLastPage}
                      onLoadMore={handleLoadMoreEmployees}
                      loading={isLoadingEmployees}
                    />
                  )}
                </RHFSelect>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableSx}>قيمة العهدة</Typography>
              <Box sx={{ display: "flex" }}>
                <RHFTextField
                  name="value"
                  control={control}
                  type=""
                  placeholder="أدخل قيمة العهدة"
                  sx={{ ...txtFieldBorderStyle }}
                  InputProps={{
                    endAdornment: (
                      <RHFSelect
                        name="currency"
                        control={control}
                        sx={{
                          ...transparentBorderTxtField,
                        }}
                        SelectProps={{
                          native: false,
                          IconComponent: () => (
                            <img src="/assets/icons/Icon.svg" alt="" />
                          ),
                        }}
                      >
                        <MenuItem value="KWD">KWD</MenuItem>
                      </RHFSelect>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableSx}>البيان </Typography>
              <Box sx={{ display: "flex" }}>
                <RHFTextField
                  name="statement"
                  control={control}
                  placeholder={"أدخل بيان الصرف "}
                  id="filled-multiline-static"
                  multiline
                  rows={3}
                  sx={{
                    ...multiRowTxtFieldBorderStyle,
                    "& .MuiOutlinedInput-input": {
                      fontSize: "14px",
                      width: "100%",
                    },
                  }}
                />
              </Box>
            </Grid>
            {/** actions Button */}
            <Box sx={boxOfActionButtonStyle}>
              <LoadingButton
                fullWidth
                disableRipple
                size="large"
                type="submit"
                variant="Contained"
                sx={loadingButtonStyle}
                loading={isLoadingCreate}
              >
                التالي
              </LoadingButton>
              <Button
                fullWidth
                disableRipple
                size="large"
                type="button"
                variant="Contained"
                sx={buttonStyle}
                onClick={onCloseModel}
              >
                إلغاء
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}
