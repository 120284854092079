import React, { useEffect, useState } from "react";

import {
  Box,
  Modal,
  Typography,
  Backdrop,
  Alert,
  Grid,
  Button,
  Checkbox,
} from "@mui/material";

import FormProvider, { RHFTextField } from "../../../../components/hook-form";

import { useForm } from "react-hook-form";

import { LoadingButton } from "@mui/lab";

import { useDispatch, useSelector } from "react-redux";

import {
  createDependency,
  getOpponentsDetails,
} from "../../../../redux/services/debt_collection_service";

// -----------------------------------------------------------------------------

// ----------- Styles --------------
const checkBoxConatainerStyle = {
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
};

const ModalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",
  width: { xs: "95%", sm: "543px", md: "800px", lg: "600px" },
  bgcolor: "#CCDDE4",
  pt: { xs: "60px", md: "24px", lg: "30px" },
  pb: { xs: "40px", md: "24px", lg: "30px" },
  px: { xs: "10px", sm: "20px", md: "20px", lg: "30px" },
  height: "auto",
};
const lableStyle = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#2F2B2C",
  lineHeight: "20px",
};
const txtFieldBorderStyle = {
  borderRadius: "5px",
  width: "100%",
  boxShadow: "#101828",

  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",

    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};
const checkBoxSx = {
  color: "#6A8490",
  "&.Mui-checked": {
    color: "#6A8490",
  },
};

const loadingButtonStyle = {
  fontSize: "16px",
  width: "173px",
  height: "48px",
  fontWeight: 600,
  bgcolor: "#97BDCE",
  color: "#2F2B2C",
  p: "10px 18px 10px 18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const modalTitleSx = {
  textAlign: "center",
  fontWeight: 500,
  color: "#2F2B2C",
  fontSize: "24px",
  lineHeight: "36px",
  pb: "10px",
};
const lableCheckBoxStyle = {
  fontSize: "14px",
  fontWeight: 400,
  color: "#2F2B2C",
};
const buttonStyle = {
  ml: "10px",
  fontSize: "16px",
  height: "48px",
  width: "173px",
  fontWeight: 400,
  bgcolor: "#FFF",
  color: "#344054",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "18px",
  paddingRight: "18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const boxOfActionButtonStyle = {
  display: "flex",
  justifyContent: "space-between",
  mt: "30px",
};

const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#2F2B2C",
  paddingBottom: "4px",
  paddingTop: "10px",
};

const boxOfModalStyle = { position: "absolute", top: "22px", left: "22px" };

//_______________________________________________________________

export default function AddVoucherModal({ open, onCloseModel }) {
  const dispatch = useDispatch();

  const { opponentId, isLoading } = useSelector(
    (state) => state.deptCollection
  );
  const [checked, setChecked] = useState(true);

  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = event.target.files;

    setFiles(files);
  };

  const defaultValues = {
    name: "",
    phone: "",
    email: "",
    relationship: "",
  };

  const methods = useForm({
    // resolver: yupResolver(CreateLegalExpenseScheema),
    defaultValues,
  });

  const {
    control,
    reset,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    console.log("data", data);
    const formData = new FormData();
    formData.append("opponent", opponentId);
    formData.append("email", data.email);
    formData.append("full_name", data.full_name);
    formData.append("attribute", data.attribute);
    formData.append("phone_number", data.phone_number);
    formData.append("save_to_contact", checked ? 1 : 0);

    // Convert FileList to Array and append files
    const filesArray = Array.from(files || []);
    filesArray.forEach((file, index) => {
      formData.append(`attachments[${index}]file`, file);
    });

    dispatch(createDependency({ data: formData })).then((action) => {
      if (createDependency.fulfilled.match(action)) {
        onCloseModel();
        dispatch(getOpponentsDetails({ id: opponentId }));
      }
    });
  };

  useEffect(() => {
    // dispatch(getAllCurrencies());
  }, [dispatch]);

  // ----------- JSX Code -------------
  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModel}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={{ ...ModalSx }}>
          <Box sx={boxOfModalStyle} onClick={onCloseModel}>
            <img src="/assets/icons/x.svg" alt="" style={{}} />
          </Box>

          <Typography sx={modalTitleSx}>إضافة كفيل </Typography>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}

            <Grid container columnSpacing={4} mt="10px">
              <Grid item xs={12} sm={6} mt="10px">
                <Typography sx={lableStyle}>اسم الكفيل</Typography>
                <Box style={{ display: "flex" }}>
                  <RHFTextField
                    name="full_name"
                    control={control}
                    placeholder="ادخل اسم الكفيل"
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} mt="10px">
                <Typography sx={lableStyle}>رقم الهاتف </Typography>
                <Box style={{ display: "flex" }}>
                  <RHFTextField
                    name="phone_number"
                    placeholder="9xxxxxxx"
                    control={control}
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} mt="10px">
                <Typography sx={lableStyle}>البريد الإلكتروني </Typography>
                <Box style={{ display: "flex" }}>
                  <RHFTextField
                    name="email"
                    placeholder="ادخل البريد الإلكتروني"
                    control={control}
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={lableStyle} mt="10px">
                  صفة الكفيل{" "}
                </Typography>
                <Box style={{ display: "flex" }}>
                  <RHFTextField
                    name="attribute"
                    placeholder="ادخل صفة الكفيل"
                    control={control}
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} mt="30px">
                {/** upload attachment */}
                <Box>
                  <Typography sx={lableSx}>المرفقات</Typography>
                  <RHFTextField
                    name="attachments"
                    control={control}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      ...txtFieldBorderStyle,
                      width: "100%",
                      marginRight: "12px",
                    }}
                    onChange={handleFileChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={checkBoxConatainerStyle}>
                  <Checkbox
                    sx={{ ...checkBoxSx }}
                    checked={checked}
                    onClick={() => {
                      setChecked(!checked);
                    }}
                  />
                  <Typography sx={lableCheckBoxStyle}>
                    تسجيل في جهات الاتصال
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/** actions Button */}
            <Box sx={boxOfActionButtonStyle}>
              <LoadingButton
                fullWidth
                disableRipple
                size="large"
                type="submit"
                variant="Contained"
                sx={loadingButtonStyle}
                loading={isLoading}
              >
                حفظ
              </LoadingButton>
              <Button
                fullWidth
                disableRipple
                size="large"
                type="button"
                variant="Contained"
                sx={buttonStyle}
                onClick={onCloseModel}
              >
                إلغاء
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}
