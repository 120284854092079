import React from "react";

import ProceduresList from "../../table/procedures-list";

// @ Mui
import { Box } from "@mui/material";

// -----------------------------------------------------------------------------

//* ------- Styles --------

//_______________________________________________-
export default function AllTab({ currentTab }) {
  // ------------- JSX Code ----------
  return (
    <>
      <Box sx={{ mt: "20px" }}>
        <ProceduresList currentTab={currentTab} />
      </Box>
    </>
  );
}
