import React, { useEffect, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";

// form
import { RHFTextField } from "../../../../components/hook-form";

import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";

import { makeStyles } from "@material-ui/core/styles";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { Controller } from "react-hook-form";

import AddVoucherModal from "../modal/AddVoucherModal";

// -----------------------------------------------------------------------------

//* ------- Styles --------

const CustomizedAccordion = styled(Accordion)(() => ({
  "&.Mui-expanded": {
    boxShadow: "none",
    margin: "0px",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    //margin: 0,
  },
  ".MuiButtonBase-root": {
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

const boxAccordionTitleStyle = {
  bgcolor: "#6A8490",
  color: "#FEFEFE",
  fontSize: "22px",
  fontWeight: 500,
};

const bodyOfAccordionStyle = {
  bgcolor: "#CCDDE4",
  paddingTop: "20px",
};

const txtFieldBorderStyle = {
  borderRadius: "5px",
  width: "100%",
  boxShadow: "#101828",

  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",

    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#1A141F",
  paddingBottom: "4px",
};

const multiRowBorderStyle = {
  width: "100%",

  boxShadow: "#101828",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    //height: "44px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};
//___________________________________________________________
export default function ContactInformation({
  setValue,
  control,
  errors,
  watch,
  methods,
}) {
  const { OppDetails } = useSelector((state) => state?.deptCollection);
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };

  const [expanded, setIsExpanded] = useState(true);

  const [phones, setPhones] = useState([...Array(1)]);

  const phoneNumbers = watch("phone_numbers") || [];

  const addNewPhoneHandler = () => {
    setValue("phone_numbers", [...phoneNumbers, { phone_number: "" }]);
  };

  const removePhoneHandler = (index) => {
    const updatedPhones = phoneNumbers.filter((_, i) => i !== index);
    setValue(
      "phone_numbers",
      updatedPhones.length > 0 ? updatedPhones : [{ phone_number: "" }]
    );
  };
  useEffect(() => {
    setPhones(phoneNumbers);
  }, [phoneNumbers]);
  const depDetails = [
    { label: "الكفيل:", value: OppDetails?.opponent_dependencies?.full_name },
    { label: "الصفة:", value: OppDetails?.opponent_dependencies?.attribute },
    {
      label: "هاتف الكفيل:",
      value: OppDetails?.opponent_dependencies?.phone_number,
    },
  ];
  // ------------- JSX Code ----------
  return (
    <>
      <CustomizedAccordion expanded={expanded}>
        <AccordionSummary
          onClick={() => {
            setIsExpanded(!expanded);
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={boxAccordionTitleStyle}
        >
          معلومات التواصل
        </AccordionSummary>
        <AccordionDetails sx={bodyOfAccordionStyle}>
          <Grid container paddingTop={"8px"}>
            {/**   */}
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={lableSx}>رقم الهاتف</Typography>

              <Box
                sx={{
                  display: "flex",
                  maxWidth: "100%",
                  flexWrap: "wrap",
                }}
              >
                {phones?.map((phone, index) => (
                  <Grid item xs={12} sm={12} md={6} mb="15px">
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        mt: "10px",
                      }}
                    >
                      <RHFTextField
                        name={`phone_numbers[${index}].phone_number`}
                        // control={control}
                        placeholder="أدخل رقم الهاتف"
                        type="text"
                        sx={{
                          ...txtFieldBorderStyle,
                          width: "100%",
                          marginRight: "12px",
                        }}
                      />

                      {index > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "center",
                          }}
                          onClick={() => removePhoneHandler(index)}
                        >
                          -
                        </Box>
                      )}

                      {index === 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={addNewPhoneHandler}
                            src="/assets/icons/plus.svg"
                            alt=""
                            style={{
                              marginRight: "10px",
                              marginLeft: "10px",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Box>
            </Grid>
          </Grid>
          {/**المحافظة */}
          {/* <Grid container paddingTop={"10px"}>
         
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={lableSx}> المحافظة</Typography>
              <RHFTextField
                name="province"
                placeholder="أدخل المحافظة"
                type="text"
                sx={{
                  ...txtFieldBorderStyle,
                }}
              />
            </Grid>
          </Grid> */}
          {/**المدينة */}

          {/* <Grid container paddingTop={"10px"}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={lableSx}> المدينة</Typography>
              <RHFTextField
                name="city"
                // control={control}
                placeholder="أدخل المدينة"
                type="text"
                sx={{
                  ...txtFieldBorderStyle,
                }}
              />
            </Grid>
          </Grid> */}

          <Grid container paddingTop={"10px"}>
            <Grid item xs={12} sm={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "0.5em",
                }}
              >
                {OppDetails?.opponent_dependencies?.id ? (
                  depDetails.map((detail, index) => (
                    <Box key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5em",
                        }}
                      >
                        <Typography
                          sx={{
                            ...lableSx,
                            color: "#6a8490",
                          }}
                        >
                          {detail.label}
                        </Typography>
                        <Typography>{detail.value}</Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box onClick={() => setOpenModal(true)}>
                    <img
                      src="/assets/icons/plus.svg"
                      alt=""
                      style={{
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    />
                    <Typography sx={lableSx}> الكفيل</Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container paddingTop={"10px"}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={lableSx}> العنوان</Typography>
              <RHFTextField
                name="address"
                // control={control}
                placeholder="أدخل العنوان"
                id="filled-multiline-static"
                multiline
                rows={3}
                type={"text"}
                sx={{
                  ...multiRowBorderStyle,
                  "& .MuiOutlinedInput-input": {
                    width: "100%",
                  },
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </CustomizedAccordion>

      {openModal && (
        <AddVoucherModal open={openModal} onCloseModel={handleClose} />
      )}
    </>
  );
}
