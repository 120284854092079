import { Helmet } from "react-helmet-async";

import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  Alert,
  MenuItem,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { LoadingButton } from "@mui/lab";





import YearCustomDatePicker from "../../../components/custom-datepicker/YearCustomDatePicker";

import { useForm } from "react-hook-form";

import FormProvider from "../../../components/hook-form/FormProvider";

import { RHFSelect } from "../../../components/hook-form";


import { toast } from "react-toastify";

import * as Yup from "yup";
import CustodyExpenseModal from "../../../sections/dashboard/collection-finance/modal/CustodyExpenseModal";
import CreateCustodyExpenseModal from "../../../sections/dashboard/collection-finance/modal/CreateCustodyExpenseModal";
import CustudyExpenseList from "./custudy-expense-list";
import { getCustudyExpenseService, getEmployeeService } from "../../../redux/services/debt_collection_service";
import LoadMoreButton from "../../../utils/LoadMoreButton";
//_________________________________________________________

//* ------- Styles --------

const lawsuitTextStyle = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "#6A8490",
};

const bigFlexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
  mt: { xs: "15px", sm: "0px" },
};

const topSaveButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#6A8490",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const topCancleButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#2F2B2C",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "160px",
  height: "40px",
  p: "12px 20px 12px 20px",
  border: "1px solid #CCDDE4",
  marginLeft: "20px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const dividerStyle = {
  mt: "20px",
};

const txtFieldBorderStyle = {
  borderRadius: "5px",
  width: "100%",
  boxShadow: "#101828",

  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",

    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};
//___________________________________________________________
export default function CustodyExpensesPage() {
  const dispatch = useDispatch();

  const { clientId } = useSelector((state) => state.deptCollection);

  const { employees,isLoadingEmployees,empLastPage ,empNext} = useSelector((state) => state.deptCollection);

    // ==================================================================
  // EMPLOYESS PAGINATION LIST
  const [employeesList, setEmployeesList] = useState(employees || []);
  useEffect(() => {
    if (employeesList.length === 0 && employees?.length > 0) {
      setEmployeesList(employees);
    }
  }, [employees]);
  const handleLoadMoreEmployees = (nextPage) => {
    dispatch(getEmployeeService({ page: nextPage })).then((res) => {
      setEmployeesList((prev) => [...prev, ...res.payload.data.data]);
    });
  };
  useEffect(() => {
    dispatch(getEmployeeService({ page: 1 })).then((res) => {
      setEmployeesList((prev) => [...prev, ...res.payload.data.data]);
    });
  }, []);
  // ==================================================================

  const navigate = useNavigate();

  const [selectedClient, setSelectedClient] = useState({});

  const [OpenModal, setOpenModal] = useState(false);

  const [OpenModalCreate, setOpenModalCreate] = useState(false);

  const defaultValues = {};

  const methods = useForm({
    defaultValues,
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleClose = () => {
    setOpenModal(null);
  };
  const handleCloseCreate = () => {
    setOpenModalCreate(null);
  };

  useEffect(() => {
    dispatch(getEmployeeService({page:1}));
  }, [clientId, dispatch]);

  useEffect(() => {
    setOpenModal(true);
  }, []);

  const onSubmit = (data) => {
    if (!data.employee) {
      toast.error("يجب اختيار موظف");
      return;
    }
    dispatch(
      getCustudyExpenseService({
        id: data.employee,
        movement_date_before: data.movement_date_before,
        movement_date_after: data.movement_date_after,
      })
    );
  };
  return (
    <>
      <Helmet>
        <title> عهدة المصروفات</title>
      </Helmet>

      {/** App Bar */}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <Box
          sx={{
            ...bigFlexBoxStyle,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={flexBoxStyle}>
            {" "}
            <img
              src="/assets/icons/arrow-left.svg"
              alt=""
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <Typography sx={lawsuitTextStyle}>عهدة المصروفات </Typography>
          </Box>

          {/** actions */}
          <Box sx={flexBoxStyle}>
            <LoadingButton
              fullWidth
              size="large"
              type="button"
              variant="Contained"
              sx={topSaveButtonStyle}
              onClick={() => {
                setOpenModalCreate(true);
              }}
            >
              صرف عهدة{" "}
            </LoadingButton>
            {/** */}
            <Button
              fullWidth
              size="large"
              variant="Contained"
              sx={topCancleButtonStyle}
            >
              إصدار تقرير
            </Button>
          </Box>
        </Box>
        {/** */}
        <Divider sx={dividerStyle} />

        <Grid container columnSpacing={2} paddingTop={"10px"}>
          <Grid item xs={12}>
            {/** List */}
            <Box sx={flexBoxStyle} width="50%" gap="1em" my={2}>
              <YearCustomDatePicker
                name="movement_date_before"
                control={control}
                setValue={setValue}
              />

              <YearCustomDatePicker
                name="movement_date_after"
                control={control}
                setValue={setValue}
              />

              <RHFSelect
                name="employee"
                control={control}
                sx={{
                  ...txtFieldBorderStyle,
                  "& .MuiSelect-select span::before": {
                    content: "'اختر موظفاً'",

                    color: "#697D95",
                    fontWeight: 300,
                    fontSize: "14px",
                  },
                }}
                SelectProps={{
                  // native: false,
                  IconComponent: () => (
                    <img
                      src="/assets/icons/Icon.svg"
                      alt=""
                      style={{
                        marginRight: "19px",
                        marginLeft: "19px",
                      }}
                    />
                  ),
                }}
                displayEmpty
                value={selectedClient}
                onChange={(e) => {
                  setValue("employee", e.target.value);
                  setSelectedClient(e.target.value);
                }}
              >
                {employeesList?.map((em, index) => (
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid  #E5E0EB",
                    }}
                    key={index}
                    value={em.id}
                  >
                    {em?.first_name + " " + em?.last_name}
                  </MenuItem>
                ))}
                    {empNext && (
                    <LoadMoreButton
                      lastPage={empLastPage}
                      onLoadMore={handleLoadMoreEmployees}
                      loading={isLoadingEmployees}
                    />
                  )}
              </RHFSelect>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="Contained"
                sx={topCancleButtonStyle}
                onClick={handleSubmit(onSubmit)}
                loading={isLoadingEmployees}
              >
                تغيير
              </LoadingButton>
            </Box>
            <CustudyExpenseList />
          </Grid>

          {/* <Grid item xs={12} sm={12} md={3}>
          <SummaryAccordion />
        </Grid> */}
        </Grid>
      </FormProvider>
      {OpenModal && (
        <CustodyExpenseModal open={OpenModal} onCloseModel={handleClose} />
      )}

      {OpenModalCreate && (
        <CreateCustodyExpenseModal
          open={OpenModalCreate}
          onCloseModel={handleCloseCreate}
        />
      )}
    </>
  );
}
