import React, { useState, useEffect } from "react";

import { Box, Typography, Grid, MenuItem, Button } from "@mui/material";

// form
import { RHFTextField, RHFSelect } from "../../../../components/hook-form";

import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";

import { LoadingButton } from "@mui/lab";

import { useDispatch, useSelector } from "react-redux";

import useClientSearch from "../../../../hooks/useClientSearch";

import { getAllClientsService } from "../../../../redux/services/general_service";

import { exportAssignmentService } from "../../../../redux/services/debt_collection_service";

import YearCustomDatePicker from "../../../../components/custom-datepicker/YearCustomDatePicker";
import { useNavigate } from "react-router-dom";

// -----------------------------------------------------------------------------

//* ------- Styles --------

const CustomizedAccordion = styled(Accordion)(() => ({
  "&.Mui-expanded": {
    boxShadow: "none",
    margin: "0px",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    //margin: 0,
  },
  ".MuiButtonBase-root": {
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

const boxAccordionTitleStyle = {
  bgcolor: "#6A8490",
  color: "#FEFEFE",
  fontSize: "22px",
  fontWeight: 500,
};

const bodyOfAccordionStyle = {
  bgcolor: "#CCDDE4",
  paddingTop: "20px",
};

const lableStyle = {
  fontSize: "14px",
  fontWeight: 500,
  color: "rgba(47, 43, 44, 1)",
  pb: "8px",
};
const topSaveButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#6A8490",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};
const MainSaveButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#97BDCE",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};
const txtFieldBorderStyle = {
  borderRadius: "5px",
  width: "100%",
  boxShadow: "#101828",
  mb: "8px",

  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",

    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};
const bigFlexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const dateNameStyle = {
  fontSize: "16px",
  fontWeight: 600,
  color: "rgba(47, 43, 44, 1)",
  lineHeight: "28px",
};

const dateValueStyle = {
  fontSize: "16px",
  fontWeight: 400,
  color: "rgba(47, 43, 44, 1)",
  lineHeight: "24px",
  ml: "5px",
};
const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
  mt: { xs: "15px", sm: "0px" },
};
const AccflexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  mt: { xs: "15px", sm: "0px" },
};
const loadingButtonStyle = {
  borderRadius: "5px",
  width: "268px",
  fontSize: "16px",
  height: "40px",
  fontWeight: 400,
  //bgcolor: "#97BDCE",
  color: "#6A8490",
  border: "2px solid #6A8490",
  paddingLeft: "18px",
  paddingright: "18px",
  paddingTop: "10px",
  paddingBottom: "10px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};
const topCancleButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#2F2B2C",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "160px",
  height: "40px",
  p: "12px 20px 12px 20px",
  border: "1px solid #CCDDE4",
  marginLeft: "20px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};
//___________________________________________________________
export default function MainInformationForm({
  setValue,
  control,
  errors,
  reset,
  handleSubmit,
  onSubmit,
  defaultValues,
}) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [selectedFileStatus, setSelectedFileStatus] = useState({});

  const [selectedAction, setSelectedActionStage] = useState({});

  const [selectedCourt, setSelectedCourt] = useState({});

  const { clients } = useSelector((state) => state.general);

  const { courts } = useSelector((state) => state.matter);

  const { filteredClients } = useClientSearch(clients);

  const [selectedClient, setSelectedClient] = useState({});
  const [expanded, setIsExpanded] = useState(true);

  const {
    OppDetails,
    clientsCollection,
    fileStatus,
    actionStage,
    isLoadingExport,
    opponentId,
    isLoadingOppUpdate,
  } = useSelector((state) => state?.deptCollection);
  const file_status = fileStatus?.map((item) => ({
    value: item[0],
    label: item[1],
  }));

  const action_stage = actionStage?.map((item) => ({
    value: item[0],
    label: item[1],
  }));

  const handleExportAssignment = () => {
    dispatch(exportAssignmentService({ id: opponentId }));
  };

  useEffect(() => {
    dispatch(getAllClientsService({ page: 1 }));
  }, []);

  useEffect(() => {
    setSelectedClient(OppDetails?.client);
    setSelectedCourt(courts?.find((c) => c.id === OppDetails?.execution_court));
    setSelectedFileStatus(OppDetails?.file_status);
    setSelectedActionStage(OppDetails?.action_stage);
  }, [OppDetails, filteredClients]);

  // ------------- JSX Code ----------
  return (
    <>
      <CustomizedAccordion expanded={expanded}>
        <AccordionSummary
          // onClick={() => {
          //   setIsExpanded(!expanded);
          // }}
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={boxAccordionTitleStyle}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={boxAccordionTitleStyle}>معلومات أساسية</Typography>

            <Box sx={flexBoxStyle}>
              <LoadingButton
                // fullWidth
                onClick={handleSubmit(onSubmit)}
                size="large"
                type="submit"
                variant="Contained"
                sx={MainSaveButtonStyle}
                loading={isLoadingOppUpdate}
              >
                حفظ
              </LoadingButton>
              {/** */}
              <Button
                // fullWidth
                onClick={() => navigate(-1)}
                size="large"
                // type="submit"
                variant="Contained"
                sx={topCancleButtonStyle}
              >
                الغاء
              </Button>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={bodyOfAccordionStyle}>
          {/** */}
          <Grid container columnSpacing={6} paddingTop={"10px"}>
            {/** حالة الملف  */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>حالة الملف</Typography>
              <RHFSelect
                name="file_status"
                control={control}
                value={selectedFileStatus}
                sx={{
                  ...txtFieldBorderStyle,
                  "& .MuiSelect-select span::before": {
                    content: "'اختر حالة الملف'",
                    color: "#697D95",
                    fontWeight: 300,
                    fontSize: "14px",
                  },
                }}
                SelectProps={{
                  native: false,
                  IconComponent: () => (
                    <img
                      src="/assets/icons/Icon.svg"
                      alt=""
                      style={{
                        marginRight: "19px",
                        marginLeft: "19px",
                      }}
                    />
                  ),
                }}
                displayEmpty
                onChange={(e) => {
                  setSelectedFileStatus(e.target.value);
                  setValue("file_status", e.target.value, {
                    shouldValidate: true,
                  });
                }}
              >
                {file_status.map((s, index) => (
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid  #E5E0EB",
                    }}
                    key={index}
                    value={s.value}
                  >
                    {s.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>

            {/** مرحلة العمل */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>مرحلة العمل</Typography>
              <RHFSelect
                name="action_stage"
                value={selectedAction}
                control={control}
                sx={{
                  ...txtFieldBorderStyle,
                  "& .MuiSelect-select span::before": {
                    content: "' اختر مرحلة العمل'",
                    color: "#697D95",
                    fontWeight: 300,
                    fontSize: "14px",
                  },
                }}
                SelectProps={{
                  native: false,
                  IconComponent: () => (
                    <img
                      src="/assets/icons/Icon.svg"
                      alt=""
                      style={{
                        marginRight: "19px",
                        marginLeft: "19px",
                      }}
                    />
                  ),
                }}
                displayEmpty
                onChange={(e) => {
                  setSelectedActionStage(e.target.value);
                  setValue("action_stage", e.target.value, {
                    shouldValidate: true,
                  });
                }}
              >
                {action_stage.map((st, index) => (
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid  #E5E0EB",
                    }}
                    key={index}
                    value={st.value}
                  >
                    {st.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
          </Grid>

          {/** */}
          <Grid container columnSpacing={6} paddingTop={"10px"}>
            {/**  محكمة التنفيذ  */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}> محكمة التنفيذ</Typography>
              <RHFSelect
                name="execution_court"
                control={control}
                value={selectedCourt}
                sx={{
                  ...txtFieldBorderStyle,
                  "& .MuiSelect-select span::before": {
                    content: "'اختر محكمة التنفيذ'",
                    color: "#697D95",
                    fontWeight: 300,
                    fontSize: "14px",
                  },
                }}
                SelectProps={{
                  native: false,
                  IconComponent: () => (
                    <img
                      src="/assets/icons/Icon.svg"
                      alt=""
                      style={{
                        marginRight: "19px",
                        marginLeft: "19px",
                      }}
                    />
                  ),
                }}
                displayEmpty
                onChange={(e) => {
                  setSelectedCourt(e.target.value);
                  setValue("execution_court", e.target.value, {
                    shouldValidate: true,
                  });
                }}
              >
                {console.log("courts", courts)}
                {courts?.map((court, index) => (
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid  #E5E0EB",
                    }}
                    key={index}
                    value={court}
                  >
                    {court.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>

            {/**  سبب التنفيذ */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>سبب التنفيذ</Typography>
              <RHFTextField
                name="reason_for_execution"
                // control={control}
                type="text"
                placeholder="أدخل سبب التنفيذ"
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>
          </Grid>

          {/** */}
          <Grid container columnSpacing={6} paddingTop={"10px"}>
            {/**  الإسناد  */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>الإسناد</Typography>
              <RHFSelect
                name="client"
                control={control}
                value={selectedClient}
                sx={{
                  ...txtFieldBorderStyle,
                  "& .MuiSelect-select span::before": {
                    content: "'اختر موكلاً'",
                    color: "#697D95",
                    fontWeight: 300,
                    fontSize: "14px",
                  },
                }}
                SelectProps={{
                  native: false,
                  IconComponent: () => (
                    <img
                      src="/assets/icons/Icon.svg"
                      alt=""
                      style={{
                        marginRight: "19px",
                        marginLeft: "19px",
                      }}
                    />
                  ),
                }}
                displayEmpty
                onChange={(e) => {
                  setValue("client", e.target.value, {
                    shouldValidate: true,
                  });
                  setSelectedClient(e.target.value);
                }}
              >
                {filteredClients?.map((client, index) => (
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid  #E5E0EB",
                    }}
                    key={index}
                    value={client?.id}
                  >
                    {client?.full_name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>

            {/**   الرقم الآلي */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>الرقم الآلي</Typography>
              <RHFTextField
                name="automatic_number"
                // control={control}
                type={"text"}
                placeholder="XXXXXXX0"
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={6} paddingTop={"10px"}>
            {/**الجنسية */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>الجنسية</Typography>
              <RHFTextField
                name="nationality"
                // control={control}
                placeholder="أدخل الجنسية"
                type="text"
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                }}
              />
            </Grid>

            {/** ارقم المدني */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>الرقم المدني</Typography>
              <RHFTextField
                name="civil_number "
                placeholder="أدخل الرقم المدني"
                type={"text"}
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={6} paddingTop={"10px"}>
            {/**الرقم المفصول */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>الرقم المفصول</Typography>
              <RHFTextField
                name="separated_number"
                placeholder="أدخل الرقم المفصول"
                type={"text"}
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                }}
              />
            </Grid>

            {/** تاريخ نزول المحكمة */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>تاريخ نزول المحكمة</Typography>
              <YearCustomDatePicker
                name="date_of_court_filing"
                setValue={setValue}
                sx={{ ...txtFieldBorderStyle }}
              />
            </Grid>
          </Grid>

          {/** */}
          <Grid
            container
            columnSpacing={6}
            paddingTop={"10px"}
            alignItems={"center"}
          >
            {/**  رقم أمر الأداء  */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>رقم أمر الأداء</Typography>
              <RHFTextField
                name="performance_number_value"
                // control={control}
                placeholder="XXXXXXX"
                type={"number"}
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>
            {/**  قيمة أمر الأداء  */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>قيمة أمر الأداء</Typography>
              <RHFTextField
                name="performance_fee_value"
                // control={control}
                placeholder="قيمة أمر الأداء"
                type={"number"}
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>

            {/** */}
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ display: "flex", alignItems: "center", mt: "20px" }}>
                <Typography sx={dateNameStyle}>تاريخ الورود:</Typography>

                <Typography sx={dateValueStyle}>
                  {OppDetails?.entry_date}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/** */}
          <Grid
            container
            columnSpacing={6}
            paddingTop={"20px"}
            alignItems={"center"}
            display={"flex"}
          >
            {/**   */}
            <Grid item xs={12} sm={12} md={6}>
              <LoadingButton
                fullWidth
                size="large"
                //type="submit"
                variant="Contained"
                sx={loadingButtonStyle}
                loading={isLoadingExport}
                onClick={handleExportAssignment}
              >
                إصدار تنبيه وتكليف
              </LoadingButton>
            </Grid>

            {/**  */}
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={dateNameStyle}>رقم العقد:</Typography>

                <Typography sx={dateValueStyle}>
                  {OppDetails?.contract_number}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </CustomizedAccordion>
    </>
  );
}
