import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Typography,
  Alert,
  MenuItem,
  Divider,
  Button,
  InputBase,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "../../../components/hook-form";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

import YearCustomDatePicker from "../../../components/custom-datepicker/YearCustomDatePicker";

import { useDispatch, useSelector } from "react-redux";

import { LoadingButton } from "@mui/lab";

import AddNewClientButton from "../../../utils/AddNewClientButton";

import useClientSearch from "../../../hooks/useClientSearch";

import { getAllClientsService } from "../../../redux/services/general_service";

import moment from "moment";

import { getImportExportsService } from "../../../redux/services/finance_service";

import { successToast } from "../../../utils/toastMessage";
import { toast } from "react-toastify";
import LoadMoreButton from "../../../utils/LoadMoreButton";
import useOppontmentSearch from "../../../hooks/useOppontmentSearch";
import { getOpponentsForCollection } from "../../../redux/services/debt_collection_service";

//____________________________________________________

//---------- Styles -----------

const containerStyle = {
  paddingLeft: { xs: "10px", sm: "0px" },
  paddingRight: { xs: "10px", sm: "0px" },
};

const lawsuitTextStyle = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "#6A8490",
};

const bigFlexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
};

const createLawsuitButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#6A8490",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const createReportButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#2F2B2C",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "160px",
  height: "40px",
  p: "12px 20px 12px 20px",
  border: "1px solid #CCDDE4",
  marginLeft: "20px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const dividerStyle = {
  mt: "20px",
  mb: "20px",
};

const txtFieldBorderStyle = {
  width: "100%",
  boxShadow: "#101828",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#2F2B2C",
  lineHeight: "20px",
  paddingBottom: "10px",
};

const nameFileStyle = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#FEFEFE",
  lineHeight: "20px",
};

const fileBoxStyle = {
  bgcolor: "#6A8490",
  padding: "10px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "fit-content",
  marginLeft: "10px",
  marginRight: "10px",
  marginBottom: "8px",
  marginTop: "8px",
};

const boxStyle = {
  display: "flex",
  flexDirection: "column",
};

const lableCheckBoxStyle = {
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#2F2B2C",
  mb: "10px",
};

const checkBoxConatainerStyle = {
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  flexDirection: "column",
};

//_______________________________________________________
export default function AccountsPage() {
  const {
    clients,

    clientsLastPage,
    clientsNext,
    isLoading,
  } = useSelector((state) => state.general);
  const { opponent } = useSelector((state) => state.deptCollection);
  const { searchTermOppontment, handleSearchOppontment } =
    useOppontmentSearch(opponent);
  // ==================================================================
  // CLIENTS PAGINATION LIST
  const [clientsList, setClientList] = useState(clients || []);
  useEffect(() => {
    if (clientsList.length === 0 && clients?.length > 0) {
      setClientList(clients);
    }
  }, [clients]);
  const handleLoadMoreClients = (nextPage) => {
    dispatch(getAllClientsService({ page: nextPage })).then((res) => {
      setClientList((prev) => [...prev, ...res.payload.data.data]);
    });
  };
  // ==================================================================
  const { searchTerm, filteredClients, handleSearch } =
    useClientSearch(clients);

  const { isLoadingCreate } = useSelector((state) => state.finance);
  const navigate = useNavigate();
  const [selectedOpponents, setSelectedOpponents] = useState([]);

  const dispatch = useDispatch();

  const [all, setIsAll] = useState(false);

  const [selectedClients, setSelectedClients] = useState([]);

  const [selectedNumber, setSelectedNumber] = useState([]);

  const [data, setData] = useState({});

  const handleRemove = (fileId) => {
    setSelectedClients((prevItems) =>
      prevItems.filter((item) => item !== fileId)
    );
    setIsAll(false);
  };

  const handleRemoveNumber = (fileId) => {
    setSelectedNumber((prevItems) =>
      prevItems.filter((item) => item !== fileId)
    );
  };

  const FormScheema = Yup.object().shape({});

  const defaultValues = {
    date_before: null,
    date_after: null,
  };

  const methods = useForm({
    resolver: yupResolver(FormScheema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    // dispatch(
    //   getImportExportsService({
    //     client: data.client === "all" ? "" : data?.client,
    //     automated_figure: all !== true && data?.automated_figure,
    //     date_after:
    //       data?.date_after !== null
    //         ? moment(data?.date_after).format("YYYY-MM-DD")
    //         : null,
    //     date_before:
    //       data?.date_before !== null
    //         ? moment(data?.date_before).format("YYYY-MM-DD")
    //         : null,
    //   })
    // ).then((response) => {
    //   if (response?.payload?.status === 200) {
    //     setData(response?.payload?.data?.data);
    //     successToast("تم تحديث الصادرات والواردات ");
    //   } else {
    //     response?.payload?.errors?.map((error, index) => toast.warn(error));
    //   }
    // });
  };

  useEffect(() => {
    dispatch(getAllClientsService({ page: 1 })).then((res) => {
      setClientList((prev) => [...prev, ...res.payload.data.data]);
    });
  }, [dispatch]);

  //___________________________________________________________________

  return (
    <>
      {/** Top Side */}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={bigFlexBoxStyle}>
          <Typography sx={lawsuitTextStyle}>الحسابات</Typography>

          <Box sx={flexBoxStyle}>
            {/** توليد */}
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              //  variant="Contained"
              sx={createLawsuitButtonStyle}
              loading={isLoadingCreate}
              //   onClick={handleSubmit(onSubmit)}
            >
              توليد
              <img
                src="/assets/icons/chevron-down.svg"
                alt=""
                style={{ marginRight: "10px" }}
              />
            </LoadingButton>
            {/** */}
            {/** الغاء */}
            <Button
              fullWidth
              onClick={() => navigate(-1)}
              size="large"
              type="submit"
              variant="Contained"
              sx={createReportButtonStyle}
            >
              الغاء
            </Button>
          </Box>
        </Box>

        {/** */}
        <Divider sx={dividerStyle} />

        <Box sx={containerStyle}>
          {" "}
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}
          <Grid container columnSpacing={1}>
            <Grid item xs={12} sm={12} md={4.6}>
              {/** clients  */}
              <Box sx={boxStyle}>
                <Typography sx={{ ...lableSx }}> الموكل</Typography>
                <RHFSelect
                  name="client"
                  sx={{
                    ...txtFieldBorderStyle,
                    "& .MuiSelect-select span::before": {
                      content: "'اختر موكلاً'",
                      color: "#697D95",
                      fontWeight: 300,
                      fontSize: "14px",
                    },
                  }}
                  SelectProps={{
                    native: false,
                    IconComponent: () => (
                      <img
                        src="/assets/icons/Icon.svg"
                        alt=""
                        style={{
                          marginRight: "19px",
                          marginLeft: "19px",
                        }}
                      />
                    ),
                  }}
                  onChange={(e) => {
                    setValue("client", e.target.value, {
                      shouldValidate: true,
                    });
                    dispatch(
                      getOpponentsForCollection({ id: e.target.value, page: 1 })
                    );

                    e.target.value === "all" ? setIsAll(true) : setIsAll(false);
                  }}
                >
                  <MenuItem
                    value="all"
                    onClick={() => {
                      setValue("client", "all", {
                        shouldValidate: true,
                      });
                      setIsAll(true);
                    }}
                  >
                    كل الموكلين
                  </MenuItem>
                  <InputBase
                    sx={{
                      padding: "10px",
                      fontSize: "14px",
                      fontWeight: 300,
                      color: "#697D95",
                    }}
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="ابحث عن موكل"
                  />
                  {clientsList?.map((client, index) => (
                    <MenuItem
                      sx={{
                        borderBottom: "1px solid  #E5E0EB",
                      }}
                      key={index}
                      value={client?.id}
                    >
                      {client?.full_name} {client?.full_name}
                    </MenuItem>
                  ))}
                  <AddNewClientButton />
                  {clientsNext && (
                    <LoadMoreButton
                      lastPage={clientsLastPage}
                      onLoadMore={handleLoadMoreClients}
                      loading={isLoading}
                    />
                  )}
                </RHFSelect>
              </Box>

              {/** file section of clients */}
              <Box sx={{ display: "flex", maxWidth: "100%", flexWrap: "wrap" }}>
                {selectedClients?.map((select, index) => (
                  <Box key={index} value={select} sx={{ ...fileBoxStyle }}>
                    <Typography sx={nameFileStyle}>{select}</Typography>
                    <img
                      src="/assets/icons/remove.svg"
                      alt=""
                      style={{ paddingRight: "5px" }}
                      onClick={() => handleRemove(select)}
                    />
                  </Box>
                ))}
              </Box>

              {all ? (
                <Box></Box>
              ) : (
                <>
                  {" "}
                  {/** Auto number */}
                  <Box sx={{ ...boxStyle, mt: "10px" }}>
                    <Typography sx={lableSx}> الخصم</Typography>

                    <RHFSelect
                      name="opponent"
                      // control={control}
                      sx={{
                        ...txtFieldBorderStyle,
                        "& .MuiSelect-select span::before": {
                          content: "'اختر خصما'",

                          color: "#697D95",
                          fontWeight: 300,
                          fontSize: "14px",
                        },
                      }}
                      SelectProps={{
                        // native: false,
                        IconComponent: () => (
                          <img
                            src="/assets/icons/Icon.svg"
                            alt=""
                            style={{
                              marginRight: "19px",
                              marginLeft: "19px",
                            }}
                          />
                        ),
                      }}
                      displayEmpty
                      value={selectedOpponents}
                      onChange={(e) => {
                        setValue("opponent", e.target.value);
                        setSelectedOpponents(e.target.value);
                      }}
                    >
                      <InputBase
                        sx={{
                          padding: "10px",
                          fontSize: "14px",
                          fontWeight: 300,
                          color: "#697D95",
                        }}
                        value={searchTermOppontment}
                        onChange={handleSearchOppontment}
                        placeholder="ابحث عن خصم"
                      />
                      {opponent?.map((opponent, index) => (
                        <MenuItem
                          sx={{
                            borderBottom: "1px solid  #E5E0EB",
                          }}
                          key={index}
                          value={opponent.id}
                        >
                          {opponent?.full_name}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Box>
                  {/** file section of auto Number */}
                  <Box
                    sx={{ display: "flex", maxWidth: "100%", flexWrap: "wrap" }}
                  >
                    {selectedNumber?.map((select, index) => (
                      <Box key={index} value={select} sx={{ ...fileBoxStyle }}>
                        <Typography sx={nameFileStyle}>{select}</Typography>
                        <img
                          src="/assets/icons/remove.svg"
                          alt=""
                          style={{ paddingRight: "5px" }}
                          onClick={() => handleRemoveNumber(select)}
                        />
                      </Box>
                    ))}
                  </Box>
                </>
              )}

              {/** date */}
              <Box sx={{ ...boxStyle, mt: "10px" }}>
                {" "}
                <Typography sx={{ ...lableSx }}> التاريخ</Typography>
                <Box sx={bigFlexBoxStyle}>
                  <YearCustomDatePicker
                    name="date_after"
                    sx={{ ...txtFieldBorderStyle }}
                    setValue={setValue}
                  />
                  <Typography
                    sx={{
                      ...lableSx,
                      mr: "6px",
                      ml: "6px",
                      paddingBottom: "0px",
                    }}
                  >
                    {" "}
                    الى
                  </Typography>
                  <YearCustomDatePicker
                    name="date_before"
                    sx={{ ...txtFieldBorderStyle }}
                    setValue={setValue}
                  />
                </Box>
              </Box>

              {/** checkbox */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: "30px",
                }}
              >
                <Box sx={{ ...checkBoxConatainerStyle, mb: "20px" }}>
                  <Typography sx={lableCheckBoxStyle}>كي نت</Typography>
                  <Box
                    sx={{
                      p: "16px 33px 16px 33px",
                      border: "1px solid red",
                      borderRadius: "9px",
                      background: "red",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13.5px",
                        fontWeight: 500,
                        color: "#fff",
                      }}
                    >
                      {data?.exports}
                    </Typography>
                  </Box>
                </Box>
                {/** */}
                <Box sx={{ ...checkBoxConatainerStyle, mb: "20px" }}>
                  <Typography sx={lableCheckBoxStyle}>فرع</Typography>
                  <Box
                    sx={{
                      p: "16px 33px 16px 33px",
                      border: "1px solid green",
                      borderRadius: "9px",
                      background: "green",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13.5px",
                        fontWeight: 500,
                        color: "#fff",
                      }}
                    >
                      {data?.imports}
                    </Typography>
                  </Box>
                </Box>
                {/** */}
                <Box sx={{ ...checkBoxConatainerStyle, mb: "20px" }}>
                  <Typography sx={lableCheckBoxStyle}>شركة</Typography>
                  <Box
                    sx={{
                      p: "16px 33px 16px 33px",
                      border: "1px solid #F2C464",
                      borderRadius: "9px",
                      background: "#F2C464",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13.5px",
                        fontWeight: 500,
                        color: "#fff",
                      }}
                    >
                      {data?.total}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ ...checkBoxConatainerStyle, mb: "20px" }}>
                  <Typography sx={lableCheckBoxStyle}>رابط</Typography>
                  <Box
                    sx={{
                      p: "16px 33px 16px 33px",
                      border: "1px solid #F2C464",
                      borderRadius: "9px",
                      background: "#8764f2",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13.5px",
                        fontWeight: 500,
                        color: "#fff",
                      }}
                    >
                      {data?.total}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={7.8}></Grid>
          </Grid>
        </Box>
      </FormProvider>
    </>
  );
}
