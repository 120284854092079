import { createSlice } from "@reduxjs/toolkit";

import {
  deleteAttachment,
  getClientContract,
  getDetails,
  updateClientContract,
  updateDeptCollection,
  uploadDeptCollection,
  getOpponentsForCollection,
  getClientsCollectionService,
  searchOpponents,
  getProceduressDetails,
  createProcedure,
  getOpponentsDetailsService,
  updateOpponentService,
  getInvoices,
  getInvoiceShow,
  createInvoice,
  createDeptClientService,
  deleteProcedures,
  updateProcedures,
  getOpponentsDetails,
  updateOpponentDetails,
  getTasks,
  getClientsTemplates,
  getOppInFile,
  getFileStatusService,
  getActionStageService,
  exportAssignmentService,
  deleteTmplate,
  createTmplate,
  getTmplateFields,
  getSystemFieldsService,
  opponentFilterService,
  exportOpponentsExcelService,
  expenseStatementsService,
  createStatementsService,
  createExpensesOpp,
  checkExpense,
  getExpensesService,
  getExpensesOppService,
  getSearchOpponents,
  getCustudyExpenseService,
  createDependency,
  getProceduresTypesService,
  getPaymentsService,
  getEmployeeService,
  createEmployeeService,
  removeUserService,
  createPayment,
  getExpenseOpponents,
  createExpensesType,
  getJobsService,
  uploadManyOpponents,
} from "../services/debt_collection_service";
import { toast } from "react-toastify";
import {
  collClientsColumnsList,
  oppColumnsList,
  proceduresColumnsList,
} from "../../utils/columnsLists";
import { baseUrl } from "../../urls";

// ----------------------------------------------------------------------

const initialState = {
  isLoadinguploadColl: false,
  details: {},
  isLoadingDetails: false,
  isLoadingUpdateDetails: false,
  isLoadingDeleteAttchment: false,
  clientContract: [],
  isLoadingClientContract: false,
  isLoadingUpdateContract: false,
  opponent: [],
  isLoading: false,
  clientsCollection: [],
  clientId: null,
  procedureId: null,
  procedures: [],
  isLoadingProcedures: false,
  isLoadingCreateProcedure: false,
  opponentInfo: {},
  opponentId: null,
  isLoadingUpdate: false,
  isLoadingCreateClient: false,
  Procedures: [],

  isLoadingInvoices: false,
  invoices: [],
  isLoadingPrintInvoice: false,
  isLoadingCreateInvoice: false,
  updatedClientContract: {},
  clientName: null,
  isLoadingDeleteProcedure: false,
  isLoadingUpdateProcedure: false,
  isLoadingOppDetails: false,
  OppDetails: {},
  columnsList: proceduresColumnsList,
  isLoadingOppUpdate: false,
  collClientsColumnsList: collClientsColumnsList,
  oppColumnsList: oppColumnsList,
  isLoadingGetTasks: false,
  tasks: [],
  isLoadingtemp: false,
  templates: [],
  templatesCount: null,
  opponentsInFile: [],
  opponentsInCount: null,
  isLoadingOppInFile: [],
  tempId: null,
  count: null,
  clientCount: null,
  isLoadingFileStatus: false,
  fileStatus: [],
  isLoadingActionStage: false,
  actionStage: [],
  isLoadingExport: false,
  isLoadingDeleteTemp: false,
  proceduresCount: null,
  template: {},
  sysFields: [],
  reqFields: [],
  fieldsToEdit: [],
  queryParams: {},
  isLoadingCreateTemplate: false,
  expenseStatementsCount: null,
  expenseStatements: [],
  isLoadingCheck: false,
  expenses: [],
  expensesCount: null,
  expensesOppCount: null,
  expensesOpp: [],
  expenses_total: null,
  expenses_count: null,
  expenses_status: null,
  ExpenseId: null,
  searchOppData: {},
  custudyExpense: [],
  proceduresTypes: [],
  isLoadinProTypes: false,
  tempData: {},
  templatesLastPage: 0,
  payments: [],
  paymentsCount: null,
  isLoadingPayments: false,
  employees: [],
  jobs: [],
  isLoadingJobs: false,
  isLoadingCreateUser: false,
  isLoadingRemvoe: false,
  isLoadingCrePay: false,
  isLoadingOppExp: false,
  OppExpsCount: null,
  OppExps: [],
  clientsNext: null,
  clientsLastPage: null,
  isLoadingClientsColl: false,
  empNext: null,
  empLastPage: null,
  empCount: null,
  isLoadingEmployees: false,
  isLoadingUploadMany: false,
};

const slice = createSlice({
  name: "deptCollection",
  initialState,
  reducers: {
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    setClientName: (state, action) => {
      state.clientName = action.payload;
    },
    setOppenentId: (state, action) => {
      state.opponentId = action.payload;
    },
    setProcedureId: (state, action) => {
      state.procedureId = action.payload;
    },
    setUpdatedClientContract: (state, action) => {
      state.updatedClientContract = action.payload;
    },
    setColumnsList: (state, action) => {
      state.columnsList = action.payload;
    },

    setCollClientsColumnsList: (state, action) => {
      state.collClientsColumnsList = action.payload;
    },
    setOppColumnsList: (state, action) => {
      state.oppColumnsList = action.payload;
    },
    setOppDetails: (state, action) => {
      state.OppDetails = action.payload;
    },
    setTempId: (state, action) => {
      state.tempId = action.payload;
    },
    setTemplate: (state, action) => {
      state.template = action.payload;
    },
    setReqFields: (state, action) => {
      state.reqFields = action.payload;
    },
    setFieldsToEdit: (state, action) => {
      state.fieldsToEdit = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    setSearchOppData: (state, action) => {
      state.searchOppData = action.payload;
    },
    setTempData: (state, action) => {
      state.tempData = action.payload;
    },
  },
  extraReducers: {
    // POST =>  POST  debt_collection_service
    [uploadDeptCollection.fulfilled]: (state, action) => {
      state.isLoadinguploadColl = false;
      state.tempId = action.payload.data.id;
    },
    [uploadDeptCollection.rejected]: (state, action) => {
      state.isLoadinguploadColl = false;
      // toast.error(action?.payload?.errors[0] || "حدث خطأ أثناء إنشاء القالب");
    },
    [uploadDeptCollection.pending]: (state) => {
      state.isLoadinguploadColl = true;
    },
    // GET =>  GET  get details
    [getDetails.fulfilled]: (state, action) => {
      state.isLoadingDetails = false;
      state.details = action.payload;
    },
    [getDetails.rejected]: (state, action) => {
      state.isLoadingDetails = false;
    },
    [getDetails.pending]: (state) => {
      state.isLoadingDetails = true;
    },
    // Delete =>  Delete  attachment
    [deleteAttachment.fulfilled]: (state, action) => {
      state.isLoadingDeleteAttchment = false;
    },
    [deleteAttachment.rejected]: (state, action) => {
      state.isLoadingDeleteAttchment = false;
    },
    [deleteAttachment.pending]: (state) => {
      state.isLoadingDeleteAttchment = true;
    },

    // Delete =>  Delete  attachment
    [getClientContract.fulfilled]: (state, action) => {
      state.clientContract = action.payload;
      state.isLoadingClientContract = false;
    },
    [getClientContract.rejected]: (state, action) => {
      state.isLoadingClientContract = false;
    },
    [getClientContract.pending]: (state) => {
      state.isLoadingClientContract = true;
    },

    // POST =>  Update  Client Contract
    [updateClientContract.fulfilled]: (state, action) => {
      state.isLoadingUpdateContract = false;
      toast.success("تم تعديل العقد بنجاح");
    },
    [updateClientContract.rejected]: (state, action) => {
      state.isLoadingUpdateContract = false;
      toast.error(action?.payload?.errors[0] || "حدث خطأ أثناء تعديل العقد");
    },
    [updateClientContract.pending]: (state) => {
      state.isLoadingUpdateContract = true;
    },
    // POST =>  Update  updateDeptCollection
    [updateDeptCollection.fulfilled]: (state, action) => {
      state.isLoadingUpdateDetails = false;
    },
    [updateDeptCollection.rejected]: (state, action) => {
      state.isLoadingUpdateDetails = false;
    },
    [updateDeptCollection.pending]: (state) => {
      state.isLoadingUpdateDetails = true;
    },

    // GET =>  GET  OPP
    [getOpponentsForCollection.fulfilled]: (state, action) => {
      state.isLoading = false;

      state.opponent = action?.payload?.data?.data;
      state.count = action?.payload?.data?.count;
    },
    [getOpponentsForCollection.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getOpponentsForCollection.pending]: (state) => {
      state.isLoading = true;
    },
    // GET =>  SEARCH  OPP
    [getSearchOpponents.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.opponent = action?.payload?.data?.data;
      state.count = action?.payload?.data?.count;
      if (action?.payload?.data?.data?.length === 0) {
        toast.success("لا يوجد خصوم");
      }
    },
    [getSearchOpponents.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getSearchOpponents.pending]: (state) => {
      state.isLoading = true;
    },
    // POST =>  Update  updateDeptCollection
    [opponentFilterService.fulfilled]: (state, action) => {
      state.isLoading = false;

      state.opponent = action?.payload?.data?.data;
      state.count = action?.payload?.data?.count;
    },
    [opponentFilterService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [opponentFilterService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> GET USERS NON EMPLOYEE
    [getClientsCollectionService.fulfilled]: (state, action) => {
      state.isLoadingClientsColl = false;
      state.clientsCollection = action.payload.data;
      state.clientCount = action.payload.count;
      state.clientsNext = action.payload.next;
      state.clientsLastPage = action.payload.last_page;
    },
    [getClientsCollectionService.rejected]: (state, action) => {
      state.isLoadingClientsColl = false;
    },
    [getClientsCollectionService.pending]: (state) => {
      state.isLoadingClientsColl = true;
    },
    // ==> GET search Opponents
    [searchOpponents.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.opponent = action?.payload?.data?.data;
      state.count = action?.payload?.data?.count;
    },
    [searchOpponents.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [searchOpponents.pending]: (state) => {
      state.isLoading = true;
    },
    // ==> GET procedures
    [getProceduressDetails.fulfilled]: (state, action) => {
      state.isLoadingProcedures = false;
      state.procedures = action.payload.data;
      state.proceduresCount = action.payload.count;
    },
    [getProceduressDetails.rejected]: (state, action) => {
      state.isLoadingProcedures = false;
    },
    [getProceduressDetails.pending]: (state) => {
      state.isLoadingProcedures = true;
    },
    // ==> POST CREATE PROCEDURE
    [createProcedure.fulfilled]: (state, action) => {
      state.isLoadingCreateProcedure = false;
    },
    [createProcedure.rejected]: (state, action) => {
      state.isLoadingCreateProcedure = false;
      toast.error(action?.payload?.errors[0] || "حدث خطأ أثناء إنشاء إجراء");
    },
    [createProcedure.pending]: (state) => {
      state.isLoadingCreateProcedure = true;
    },

    // ==> GET oppe
    [getOpponentsDetailsService.fulfilled]: (state, action) => {
      state.isLoadingProcedures = false;
      state.opponentInfo = action?.payload;
    },
    [getOpponentsDetailsService.rejected]: (state, action) => {
      state.isLoadingProcedures = false;
    },
    [getOpponentsDetailsService.pending]: (state) => {
      state.isLoadingProcedures = true;
    },

    // ==> GET oppe
    [updateOpponentService.fulfilled]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateOpponentService.rejected]: (state, action) => {
      state.isLoadingUpdate = false;
    },
    [updateOpponentService.pending]: (state) => {
      state.isLoadingUpdate = true;
    },
    // INVOICES

    // Get =>  Get  get details of  invoices
    [getInvoices.fulfilled]: (state, action) => {
      state.isLoadingInvoices = false;
      state.invoices = action?.payload;
    },
    [getInvoices.rejected]: (state, action) => {
      state.isLoadingInvoices = false;
    },
    [getInvoices.pending]: (state) => {
      state.isLoadingInvoices = true;
    },
    // Get =>  Get  get details of  invoices
    [getInvoiceShow.fulfilled]: (state, action) => {
      state.isLoadingPrintInvoice = false;
    },
    [getInvoiceShow.rejected]: (state, action) => {
      state.isLoadingPrintInvoice = false;
    },
    [getInvoiceShow.pending]: (state) => {
      state.isLoadingPrintInvoice = true;
    },
    // Get =>  Get  get details of  invoices
    [createInvoice.fulfilled]: (state, action) => {
      state.isLoadingCreateInvoice = false;
    },
    [createInvoice.rejected]: (state, action) => {
      state.isLoadingCreateInvoice = false;
    },
    [createInvoice.pending]: (state) => {
      state.isLoadingCreateInvoice = true;
    },

    // Get =>  Get  get details of  invoices
    [createDeptClientService.fulfilled]: (state, action) => {
      state.isLoadingCreateClient = false;
    },
    [createDeptClientService.rejected]: (state, action) => {
      state.isLoadingCreateClient = false;
    },
    [createDeptClientService.pending]: (state) => {
      state.isLoadingCreateClient = true;
    },
    // DELETE => DELETE PROCEDURE
    [deleteProcedures.fulfilled]: (state, action) => {
      state.isLoadingDeleteProcedure = false;
    },
    [deleteProcedures.rejected]: (state, action) => {
      state.isLoadingDeleteProcedure = false;
    },
    [deleteProcedures.pending]: (state) => {
      state.isLoadingDeleteProcedure = true;
    },
    // UPDATE => UPDATE PROCEDURE
    [updateProcedures.fulfilled]: (state, action) => {
      state.isLoadingUpdateProcedure = false;
    },
    [updateProcedures.rejected]: (state, action) => {
      state.isLoadingUpdateProcedure = false;
    },
    [updateProcedures.pending]: (state) => {
      state.isLoadingUpdateProcedure = true;
    },

    // GET => GET OPP DET
    [getOpponentsDetails.fulfilled]: (state, action) => {
      state.isLoadingOppDetails = false;
      state.OppDetails = action.payload.data;
    },
    [getOpponentsDetails.rejected]: (state, action) => {
      state.isLoadingOppDetails = false;
    },
    [getOpponentsDetails.pending]: (state) => {
      state.isLoadingOppDetails = true;
    },
    // UPDATE => UPDATE OPP DET
    [updateOpponentDetails.fulfilled]: (state, action) => {
      state.isLoadingOppUpdate = false;
      toast.success("تم تعديل بيانات الخصم بنجاح");
    },
    [updateOpponentDetails.rejected]: (state, action) => {
      state.isLoadingOppUpdate = false;
      toast.error(action?.payload?.errors[0] || "حدث خطأ أثناء تعديل الخصم");
    },
    [updateOpponentDetails.pending]: (state) => {
      state.isLoadingOppUpdate = true;
    },
    // UPDATE => UPDATE OPP DET
    [getTasks.fulfilled]: (state, action) => {
      state.isLoadingGetTasks = false;
      state.tasks = action.payload;
    },
    [getTasks.rejected]: (state, action) => {
      state.isLoadingGetTasks = false;
    },
    [getTasks.pending]: (state) => {
      state.isLoadingGetTasks = true;
    },
    //GET TEMPLATES
    [getClientsTemplates.fulfilled]: (state, action) => {
      state.isLoadingtemp = false;
      state.templates = action.payload.data;
      state.templatesCount = action.payload.count;
      state.templatesLastPage = action.payload.last_page;
    },
    [getClientsTemplates.rejected]: (state, action) => {
      state.isLoadingtemp = false;
    },
    [getClientsTemplates.pending]: (state) => {
      state.isLoadingtemp = true;
    },
    // GET => GET OPP IN FILE

    [getOppInFile.fulfilled]: (state, action) => {
      state.isLoadingOppInFile = false;
      state.opponentsInFile = action.payload.data;
      state.opponentsInCount = action.payload.count;
    },
    [getOppInFile.rejected]: (state, action) => {
      state.isLoadingOppInFile = false;
    },
    [getOppInFile.pending]: (state) => {
      state.isLoadingOppInFile = true;
    },
    // GET => GET OPP IN FILE

    [getFileStatusService.fulfilled]: (state, action) => {
      state.isLoadingFileStatus = false;
      state.fileStatus = action.payload.data;
    },
    [getFileStatusService.rejected]: (state, action) => {
      state.isLoadingFileStatus = false;
    },
    [getFileStatusService.pending]: (state) => {
      state.isLoadingFileStatus = true;
    },
    // GET => GET OPP IN FILE

    [getActionStageService.fulfilled]: (state, action) => {
      state.isLoadingActionStage = false;
      state.actionStage = action.payload.data;
    },
    [getActionStageService.rejected]: (state, action) => {
      state.isLoadingActionStage = false;
    },
    [getActionStageService.pending]: (state) => {
      state.isLoadingActionStage = true;
    },
    // GET =>     EXPORT PAYMENT ISSUE

    [exportAssignmentService.fulfilled]: (state, action) => {
      state.isLoadingExport = false;
      window.open(baseUrl + action.payload.docx_url);
    },
    [exportAssignmentService.rejected]: (state, action) => {
      state.isLoadingExport = false;
    },
    [exportAssignmentService.pending]: (state) => {
      state.isLoadingExport = true;
    },
    // GET =>     EXPORT OPPONENTS EXCEL

    [exportOpponentsExcelService.fulfilled]: (state, action) => {
      state.isLoadingExport = false;
      window.open(action.payload.data.excel_url);
    },
    [exportOpponentsExcelService.rejected]: (state, action) => {
      state.isLoadingExport = false;
    },
    [exportOpponentsExcelService.pending]: (state) => {
      state.isLoadingExport = true;
    },
    // DELETE =>     DELETE TEMP
    [deleteTmplate.fulfilled]: (state, action) => {
      state.isLoadingDeleteTemp = false;
    },
    [deleteTmplate.rejected]: (state, action) => {
      state.isLoadingDeleteTemp = false;
    },
    [deleteTmplate.pending]: (state) => {
      state.isLoadingDeleteTemp = true;
    },
    // POST => CREATE TEMP

    [createTmplate.fulfilled]: (state, action) => {
      state.isLoadingCreateTemplate = false;
    },
    [createTmplate.rejected]: (state, action) => {
      state.isLoadingCreateTemplate = false;

      toast.error(action.payload.errors[0] || "حدث خطأ أثناء إنشاء القالب");
    },
    [createTmplate.pending]: (state) => {
      state.isLoadingCreateTemplate = true;
    },
    // POST => CREATE TEMP

    [getTmplateFields.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.fields = action.payload.data;
    },
    [getTmplateFields.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTmplateFields.pending]: (state) => {
      state.isLoading = true;
    },
    // GET =>     GET SYSTEM FIELDS

    [getSystemFieldsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.sysFields = action.payload.data;
    },
    [getSystemFieldsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getSystemFieldsService.pending]: (state) => {
      state.isLoading = true;
    },
    // GET =>     EXPORT EXPENS STATMENTS

    [expenseStatementsService.fulfilled]: (state, action) => {
      state.isLoadingExSt = false;
      state.expenseStatements = action.payload.data.data;
      state.expenseStatementsCount = action.payload.data.count;
    },
    [expenseStatementsService.rejected]: (state, action) => {
      state.isLoadingExSt = false;
    },
    [expenseStatementsService.pending]: (state) => {
      state.isLoadingExSt = true;
    },
    // GET =>     EXPORT EXPENS STATMENTS

    [getExpensesService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.expenses = action.payload.data.data;
      state.expensesCount = action.payload.data.count;
    },
    [getExpensesService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getExpensesService.pending]: (state) => {
      state.isLoading = true;
    },
    // POST =>     CREATE EXPENS STATMENTS
    [createStatementsService.fulfilled]: (state, action) => {
      state.ExpenseId = action.payload.data.data.id;
      state.isLoadingCreate = false;
    },
    [createStatementsService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createStatementsService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    // POST =>     CREATE EXPENS STATMENTS
    [createExpensesOpp.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createExpensesOpp.rejected]: (state, action) => {
      state.isLoadingCreate = false;
      toast.error(action?.payload?.errors[0] || "حدث خطأ أثناء إنشاء مصروف");
    },
    [createExpensesOpp.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    // GET =>     CHECK EXPENS IF EXSIST

    [checkExpense.fulfilled]: (state, action) => {
      state.isLoadingCheck = false;
    },
    [checkExpense.rejected]: (state, action) => {
      state.isLoadingCheck = false;
    },
    [checkExpense.pending]: (state) => {
      state.isLoadingCheck = true;
    },
    // GET =>     GET EXPENSESE OPP

    [getExpensesOppService.fulfilled]: (state, action) => {
      state.isLoading = false;

      state.expensesOpp = action.payload.data.data.expense_opponent;
      state.expenses_total = action.payload.data.data.summary.expenses_total;
      state.expenses_status = action.payload.data.data.summary.status;
      state.expenses_count = action.payload.data.data.summary.expenses_count;
      state.expensesOppCount = action.payload.data.count;
    },
    [getExpensesOppService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getExpensesOppService.pending]: (state) => {
      state.isLoading = true;
    },
    [getCustudyExpenseService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.custudyExpense = action.payload.data.data;
    },
    [getCustudyExpenseService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getCustudyExpenseService.pending]: (state) => {
      state.isLoading = true;
    },
    [createDependency.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createDependency.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [createDependency.pending]: (state) => {
      state.isLoading = true;
    },
    [getProceduresTypesService.fulfilled]: (state, action) => {
      state.isLoadinProTypes = false;
      state.proceduresTypes = action.payload.data.data;
    },
    [getProceduresTypesService.rejected]: (state, action) => {
      state.isLoadinProTypes = false;
    },
    [getProceduresTypesService.pending]: (state) => {
      state.isLoadinProTypes = true;
    },
    [getPaymentsService.fulfilled]: (state, action) => {
      state.isLoadingPayments = false;
      state.payments = action.payload.data.data;
      state.paymentsCount = action.payload.data.count;
    },
    [getPaymentsService.rejected]: (state, action) => {
      state.isLoadingPayments = false;
    },
    [getPaymentsService.pending]: (state) => {
      state.isLoadingPayments = true;
    },
    // ==> GET USER
    [getEmployeeService.fulfilled]: (state, action) => {
      state.isLoadingEmployees = false;
      state.employees = action.payload.data.data;
      state.empCount = action.payload.data.count;
      state.empLastPage = action.payload.data.last_page;
      state.empNext = action.payload.data.next;
    },
    [getEmployeeService.rejected]: (state, action) => {
      state.isLoadingEmployees = false;
    },
    [getEmployeeService.pending]: (state) => {
      state.isLoadingEmployees = true;
    },
    // ==> GET USER
    [getJobsService.fulfilled]: (state, action) => {
      state.isLoadingJobs = false;
      state.jobs = action.payload.data.data;
    },
    [getJobsService.rejected]: (state, action) => {
      state.isLoadingJobs = false;
    },
    [getJobsService.pending]: (state) => {
      state.isLoadingJobs = true;
    },

    [createEmployeeService.fulfilled]: (state, action) => {
      state.isLoadingCreateUser = false;
    },
    [createEmployeeService.rejected]: (state, action) => {
      state.isLoadingCreateUser = false;
    },
    [createEmployeeService.pending]: (state) => {
      state.isLoadingCreateUser = true;
    },
    [removeUserService.fulfilled]: (state, action) => {
      state.isLoadingRemvoe = false;
    },
    [removeUserService.rejected]: (state, action) => {
      state.isLoadingRemvoe = false;
    },
    [removeUserService.pending]: (state) => {
      state.isLoadingRemvoe = true;
    },
    [createPayment.fulfilled]: (state, action) => {
      state.isLoadingCrePay = false;
    },
    [createPayment.rejected]: (state, action) => {
      state.isLoadingCrePay = false;
    },
    [createPayment.pending]: (state) => {
      state.isLoadingCrePay = true;
    },
    // ==> GET USER
    [getExpenseOpponents.fulfilled]: (state, action) => {
      state.isLoadingOppExp = false;
      state.OppExps = action.payload.data.data;
      state.OppExpsCount = action.payload.data.count;
    },
    [getExpenseOpponents.rejected]: (state, action) => {
      state.isLoadingOppExp = false;
    },
    [getExpenseOpponents.pending]: (state) => {
      state.isLoadingOppExp = true;
    },
    // ==> GET USER
    [createExpensesType.fulfilled]: (state, action) => {
      state.isLoadingOppExp = false;
      toast.success("تم إضافة نوع مصروف جديد");
    },
    [createExpensesType.rejected]: (state, action) => {
      state.isLoadingOppExp = false;
    },
    [createExpensesType.pending]: (state) => {
      state.isLoadingOppExp = true;
    },
    // ==> GET USER
    [uploadManyOpponents.fulfilled]: (state, action) => {
      state.isLoadingUploadMany = false;
      toast.success("تم الرفع بنجاح ");
    },
    [uploadManyOpponents.rejected]: (state, action) => {
      state.isLoadingUploadMany = false;
      toast.error(action?.payload?.errors[0] || "حدث خطأ أثناء عملية الرفع");
    },
    [uploadManyOpponents.pending]: (state) => {
      state.isLoadingUploadMany = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setInvoceId,
  setClientId,
  setClientName,
  setOppenentId,
  setUpdatedClientContract,
  setProcedureId,
  setColumnsList,
  setCollClientsColumnsList,
  setOppColumnsList,
  setOppDetails,
  setTempId,
  setTemplate,
  setReqFields,
  setFieldsToEdit,
  setQueryParams,
  setSearchOppData,
  setTempData,
} = slice.actions;
